import {
  Button,
  Col,
  List,
  PageHeader,
  Row,
  Tabs,
  Tag,
} from "antd";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePermission } from "../PermissionsProvider";
import { RsvpResponse, rsvpReponseToTagColor, Rsvp } from "../RSVP/rsvp";
import { Concert } from "../types";
import { StatusToLabel } from "../utils";
import { useConcerts, useUserConcerts } from "../api-hooks/api";
import { useUserRsvps } from "../api-hooks/rsvp";


function ConcertItem({
  concert,
  userRsvp,
  userDidParticipate,
}: {
  concert: Concert;
  userRsvp: Rsvp | undefined;
  userDidParticipate: boolean;
}) {
  const navigate = useNavigate();

  // TODO: userDidParticipate is only meaningful for past concerts. Also, the field is better modelled as either undefined or true.

  return (
    <List.Item actions={[]}>
      <Row align="middle" style={{ width: "100%" }} gutter={[8, 8]}>
        <Col xs={24} sm={20} md={21} lg={22} xxl={23}>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12}>
              <span>{dayjs(concert.date).format("DD.MM.YYYY")}</span>
              <span> </span>
              <span>{concert.title}</span>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <span>
                <StatusToLabel status={concert.status} />{" "}
              </span>
              {userRsvp !== undefined &&
                userRsvpReponseToTag(userRsvp.response)}
              {userDidParticipate === true && (
                <Tag color="purple">Du deltok</Tag>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={4} md={3} lg={2} xxl={1}>
          <Button onClick={() => navigate(`/concerts/${concert.id}`)}>
            Detaljer
          </Button>
        </Col>
      </Row>
    </List.Item>
  );
}

function userRsvpReponseToTag(response: RsvpResponse) {
  switch (response) {
    case "yes":
      return <Tag color={rsvpReponseToTagColor(response)}>Ditt svar: Ja</Tag>;
    case "no":
      return <Tag color={rsvpReponseToTagColor(response)}>Ditt svar: Nei</Tag>;
  }
}

function Concerts() {

  const navigate = useNavigate();
  const canCreateConcerts = usePermission("write:concerts");

  const { data: concertData, loading } = useConcerts(true)
  const { data: rsvpData } = useUserRsvps()
  const { data: userConcerts } = useUserConcerts()

  const [searchParams, setSearchParams] = useSearchParams();

  const tabs = [
    {
      label: "Kommende",
      key: "upcoming",
      children: <>
        <List
          size="large"
          bordered
          dataSource={concertData.filter((item) => {
            return dayjs(item.date).isAfter(dayjs(), "day") || dayjs(item.date).isSame(dayjs(), "day");
          })}
          loading={loading}
          renderItem={(item) => (
            <ConcertItem
              concert={item}
              userRsvp={rsvpData.find((rsvpItem) => rsvpItem.concert === item.id)}
              userDidParticipate={false}
            />
          )}
        />
      </>
    },
    {
      label: "Tidligere",
      key: "past",
      children: <>
        <List
          size="large"
          bordered
          dataSource={concertData.filter((item) => {
            return dayjs(item.date).isBefore(dayjs()) && !dayjs(item.date).isSame(dayjs(), "day");
          }).reverse()}
          loading={loading}
          renderItem={(item) => (
            <ConcertItem
              concert={item}
              userRsvp={rsvpData.find((rsvpItem) => rsvpItem.concert === item.id)}
              userDidParticipate={
                userConcerts.find((userConcert) => userConcert.id === item.id) !==
                undefined
              }
            />
          )}
        />
      </>
    }
  ]

  return (
    <>
      <PageHeader
        title={`Konserter`}
        extra={
          canCreateConcerts ? (
            <Button key="1" onClick={() => navigate(`/concerts/create`)}>
              Ny Konsert
            </Button>
          ) : null
        }
      />
      <Tabs
        items={tabs}
        onChange={(key) => setSearchParams({ tab: key })}
        activeKey={searchParams.get("tab") || "upcoming"}
        defaultActiveKey={searchParams.get("tab") || "upcoming"}
      />
    </>
  );
}

export default Concerts;
