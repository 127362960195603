import { API_URL } from "../constants";
import { ConcertFee } from "../Concert/ConcertFees";
import { useApiDelete, useApiGetArray, useApiPatch, useApiPost } from "./generic";


export function useConcertFees(concertId: number) {
    const url = `${API_URL}/fees/group?concert=${concertId}`;
    return useApiGetArray<ConcertFee>(url);
}

export function useDeleteConcertFee(feeId: number) {
    const url = `${API_URL}/fees/group/${feeId}`;
    return useApiDelete(url);
}

export function useCreateConcertFee() {
    const url = `${API_URL}/fees/group`;
    return useApiPost<ConcertFee>(url);
}

export function useEditConcertFee() {
    const url = `${API_URL}/fees/group`;
    return useApiPatch<ConcertFee>(url);
}