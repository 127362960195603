import { Form, Button, Divider, message, Spin, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { FormValues, ReportFormCore } from "./ReportFormCore";
import { useConcerts, useUserConcerts } from "../api-hooks/api";
import { useCreateUserReport } from "../api-hooks/report";
import { useState } from "react";

export const CreateReport = () => {
    const navigate = useNavigate();
    const { loading: concertListLoading, data: concertData } = useConcerts(true)
    const { data: userConcerts } = useUserConcerts(true)
    const [showWarning, setShowWarning] = useState(false)
    const { loading: createReportLoading, execute } = useCreateUserReport()
    const loading = concertListLoading || createReportLoading

    const createReport = async (values: any) => {
        const createdReport = await execute({
            activity: values.concertId,
            comment: values.comment,
            items: values.items
        })

        if (createdReport) {
            navigate(`/expenses/${createdReport.id}`)
            message.success("Rapporten ble opprettet")
        }
    }

    const onFinish = (values: FormValues) => {
        if (values.items === undefined || values.items.length === 0) {
            message.error("Det må være minst ett utlegg i rapporten")
            return
        }
        createReport(values)
    };

    const onChange = (changedValues: { concertId?: number }) => {
        if (changedValues.concertId !== undefined) {
            const userRegisteredAsParticipant = userConcerts.some(c => c.id === changedValues.concertId)
            setShowWarning(!userRegisteredAsParticipant)
        }
    }

    const options = concertData.filter(c => c.status === "confirmed" || c.status === "cancelled").map((c) => {
        return { label: `${dayjs(c.date).format("DD.MM.YYYY")} - ${c.title}`, key: c.id }
    })

    const warningMessageWithLink = (
        <>
            <span>Det er ikke registrert at du deltok på konserten. </span>
            <Link to="/concerts/participation">{"Gå til Konserter -> Registrer om du har deltatt."}</Link>
        </>
    )

    return (
        <>
            <Spin spinning={loading}>
                <Form
                    name="report"
                    layout="vertical"
                    onFinish={onFinish}
                    onValuesChange={onChange}
                    autoComplete="off"
                >
                    <Divider orientation="left">Ny Rapport</Divider>
                    {showWarning && <Alert style={{ marginBottom: "1em" }} message={warningMessageWithLink} type="warning" showIcon />}
                    <ReportFormCore options={options} />
                    <Form.Item>
                        <Button type="default" onClick={() => navigate(`/expenses`)}>
                            Avbryt
                        </Button>
                        <Button style={{ marginLeft: "5px" }} type="primary" htmlType="submit">
                            Send inn
                        </Button>
                    </Form.Item>
                </Form>
            </Spin >
        </>
    );
};
