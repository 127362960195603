import { Input, Form, DatePicker, Select, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ConcertStatus } from "../types";

export type ConcertFormData = {
  title: string;
  description: string;
  date: moment.Moment;
  status: ConcertStatus;
  active: boolean;
};

export function ConcertFormCore() {
  return (
    <>
      <Form.Item
        label="Tittel"
        name="title"
        rules={[{ required: true, message: "Vær grei og skriv inn en tittel" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Dato"
        name="date"
        rules={[{ required: true, message: "Vær grei og velg en dato" }]}
      >
        <DatePicker format={"DD.MM.YYYY"} />
      </Form.Item>
      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: true, message: "Vær grei og velg en status" }]}
      >
        <Select>
          <Select.Option value="negotiation">Forhandlinger</Select.Option>
          <Select.Option value="rejected">Utgikk</Select.Option>
          <Select.Option value="confirmed">Bekreftet</Select.Option>
          <Select.Option value="cancelled">Avlyst</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Beskrivelse" name="description">
        <TextArea />
      </Form.Item>
      <Form.Item label="Aktiv" name="active" valuePropName="checked">
        <Switch />
      </Form.Item>
    </>
  );
}
