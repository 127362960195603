import { API_URL } from "../constants";
import { useApiGet } from "./generic";

export type UpsertContactsResponse = {
    ok: boolean;
    num_created: number;
};

export type UpsertPurchasesResponse = {
    ok: boolean;
}

export type UpdateCsvRespose = {
    uploaded: string;
}

export function useUpserContactsHook() {
    const url = `${API_URL}/fiken/hooks/upsert-contacts`;
    return useApiGet<UpsertContactsResponse>(url, false);
}

export function useUpsertContactPurchasesHook() {
    const url = `${API_URL}/fiken/hooks/upsert-purchases`;
    return useApiGet<UpsertPurchasesResponse>(url, false);
}