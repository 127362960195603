import { API_URL } from "../constants";
import { useApiDelete, useApiGet, useApiGetArray, useApiPatch, useApiPost } from "./generic";
import { ExpenseReport, NewExpenseReport, UserExpenseReport } from "../Expenses/types";
import { PatchedReport } from "../Expenses/EditReport";
import { PatchReportStatusPayload } from "../Expenses/ListReports";


export function useReport(reportId: number, all: boolean = false) {
    const url = all ? `${API_URL}/expenses/all/${reportId}` : `${API_URL}/expenses/user/${reportId}`;
    return useApiGet<ExpenseReport>(url);
}

export function useReports(all: boolean = false) {
    const url = all ? `${API_URL}/expenses/all` : `${API_URL}/expenses/user`;
    return useApiGetArray<ExpenseReport>(url);
}

export function useCreateUserReport() {
    const url = `${API_URL}/expenses/user`;
    return useApiPost<UserExpenseReport, NewExpenseReport>(url);
}

export function usePatchUserReport() {
    const url = `${API_URL}/expenses/user`;
    return useApiPatch<PatchedReport, UserExpenseReport>(url);
}

export function usePatchReportStatus() {
    const url = `${API_URL}/expenses/all`;
    return useApiPatch<PatchReportStatusPayload, ExpenseReport>(url);
}

export function useDeleteUserReport(reportId: number) {
    const url = `${API_URL}/expenses/user/${reportId}`;
    return useApiDelete(url);
}