import { List, Tag } from "antd";
import { usePersons } from "../PersonsProvider/PersonsProvider";

import { useConcertParticipants } from "../api-hooks/api";

export type Participant = {
  id: number;
  concert_id: number;
  person_id: number;
};

type Name = {
  firstName: string;
  lastName: string;
  external: boolean;
}

type ParticipantWithName = Participant & Name

export function ParticipantsList({ concertId }: { concertId: number }) {
  const { persons } = usePersons();
  const { data, loading } = useConcertParticipants(concertId);
  const participants: ParticipantWithName[] = data.map(p => {
    const person = persons.find((person) => person.id === p.person_id)
    return {
      ...p,
      firstName: person?.first_name || "<first name missing>",
      lastName: person?.last_name || "<last name missing>",
      external: person?.external || false
    }
  })

  return (
    <>
      <List
        size="default"
        bordered
        dataSource={participants.sort((a, b) => a.firstName.localeCompare(b.firstName))}
        loading={loading}
        renderItem={(item) => {
          return (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={`${item.firstName} ${item.lastName}`}
              />
              <Tag hidden={item.external} color="green">Fast Musiker</Tag>
              <Tag color="purple">Bekreftet</Tag>
            </List.Item>
          );
        }}
      />
    </>
  );
}
