import { Card, Col, Divider, Dropdown, List, Menu, PageHeader, Row, Segmented, Table } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Text from "antd/lib/typography/Text";
import { ConcertInfoElement, statusToText } from "./ListUserReports";
import { usePersons } from "../PersonsProvider/PersonsProvider";
import { ExpenseReport, ReportStatus } from "./types";
import { Person } from "../Person/Persons";
import { Concert } from "../types";
import { useState } from "react";
import { SegmentedValue } from "antd/lib/segmented";
import { usePatchReportStatus, useReports } from "../api-hooks/report";
import { useConcerts } from "../api-hooks/api";

type ExpenseReportWithExtras = ExpenseReport & { person?: Person, activity?: Concert }

export type PatchReportStatusPayload = {
    id: number,
    status: ReportStatus
}

function perItemActionMenu(status: ReportStatus, setStatus: (status: ReportStatus) => void) {
    switch (status) {
        case "pending":
            return <Menu>
                <Menu.Item key="1" onClick={() => setStatus("approved")}>
                    <Text>Godkjenn</Text>
                </Menu.Item>
                <Menu.Item key="2" onClick={() => setStatus("rejected")}>
                    <Text>Avvis</Text>
                </Menu.Item>
            </Menu>
        case "rejected":
            return <Menu>
                <Menu.Item key="1" onClick={() => setStatus("pending")}>
                    <Text>Trekk tilbake avvisning</Text>
                </Menu.Item>
            </Menu>
        case "approved":
            return <Menu>
                <Menu.Item key="1" onClick={() => setStatus("pending")}>
                    <Text>Trekk tilbake godkjenning</Text>
                </Menu.Item>
            </Menu>
        default:
            return <Menu>
                <Menu.Item key="1" disabled>
                    <Text>Ingen handlinger tilgjengelig</Text>
                </Menu.Item>
            </Menu>
    }
}

function ReportListItem(reportWithExtras: ExpenseReportWithExtras) {
    const navigate = useNavigate();
    const [item, setItem] = useState(reportWithExtras);

    const { loading, execute } = usePatchReportStatus()

    const columns = [
        { title: "kr", dataIndex: "amount", width: "30%" },
        { title: "Beskrivelse", dataIndex: "description", width: "70%" },
    ]

    const onStatusChange = (reportId: number) => {
        return async (status: ReportStatus) => {
            const result = await execute({ id: reportId, status: status });
            if (result !== null) {
                setItem((oldItem) => { return { ...oldItem, ...result } });
            }
        }
    }

    return <List.Item key={reportWithExtras.id}>
        <Row style={{ width: "100%" }}>
            <Col xs={24} md={12} lg={8}>
                <Card.Grid hoverable={false} style={{ width: "100%", height: "100%" }}>
                    <Row gutter={[4, 4]}>
                        <Col xs={24}>
                            {item.person ? `${item.person.first_name} ${item.person.last_name}` : "Ukjent konsert"}
                        </Col>
                        <Col xs={24}>
                            {item.activity ? <ConcertInfoElement concert={item.activity} /> : "Ukjent konsert"}
                        </Col>
                        <Col xs={24}>
                            {item.items.length} utlegg på til sammen {item.items.reduce((acc, current) => acc + current.amount, 0)} kr
                        </Col>
                        <Col xs={24}>
                            {statusToText(item.status)}
                        </Col>
                        <Col xs={24}>
                            <Dropdown.Button loading={loading} onClick={() => navigate(`/expenses/review/${item.id}`)} overlay={perItemActionMenu(item.status, onStatusChange(reportWithExtras.id))}>Detaljer</Dropdown.Button>
                        </Col>
                        <Col xs={24}>
                            <Text type="secondary">Opprettet {dayjs(item.created_at_datetime).format("DD.MM.YYYY kl HH:mm")}</Text>
                        </Col>
                    </Row>
                </Card.Grid>
            </Col>
            <Col xs={0} md={12} lg={16}>
                <Card.Grid hoverable={false} style={{ width: "100%", height: "100%" }}>
                    <Table pagination={false} bordered={false} columns={columns} showHeader={true} size="small" dataSource={item.items.map((expenseItem, idx) => {
                        return {
                            key: idx,
                            ...expenseItem
                        }
                    })} />
                </Card.Grid>
            </Col>
        </Row>
    </List.Item>
}


export function ListReports() {

    const { data: reports, loading: loadingReports } = useReports(true)
    const { data: concerts, loading: loadingConcerts } = useConcerts(null)
    const { persons } = usePersons();
    const [filterValue, setFilterValue] = useState<ReportStatus | undefined>("pending");

    const onSelectFilter = (newValue: SegmentedValue) => {
        switch (newValue.toString()) {
            case "all":
                setFilterValue(undefined);
                break;
            case "pending":
                setFilterValue("pending");
                break;
            case "approved":
                setFilterValue("approved");
                break;
            case "rejected":
                setFilterValue("rejected");
                break;
        }
    }

    const data = reports.map((item) => {
        return {
            ...item,
            activity: concerts.find((c) => c.id === item.activity_id),
            person: persons.find((person) => person.id === item.person_id)
        }
    }).filter((item) => item.activity !== undefined && item.person !== undefined).sort((a, b) => {
        // Force typescript to understand that everything is defined
        if (a.activity === undefined || a.person === undefined || b.activity === undefined || b.person === undefined) {
            return -1;
        }
        return dayjs(b.activity.date).diff(dayjs(a.activity.date)) || b.person.last_name.localeCompare(a.person.last_name)
    }).filter((item) => {
        if (filterValue === undefined) {
            return true;
        } else {
            return item.status === filterValue;
        }
    })

    return <>
        <PageHeader
            title={`Godkjenning av Utlegg`}
        />
        <Divider orientation="left">Rapporter</Divider>
        <Segmented style={{ marginLeft: "1rem" }}
            options={[
                {
                    label: 'Registrert',
                    value: 'pending',
                },
                {
                    label: 'Godkjent',
                    value: 'approved',
                },
                {
                    label: 'Avvist',
                    value: 'rejected',
                },
                {
                    label: 'Alle',
                    value: 'all',
                },
            ]}
            value={filterValue}
            onChange={onSelectFilter}
        />
        <List
            style={{ width: "100%" }}
            size="small"
            bordered={false}
            dataSource={data}
            loading={loadingReports || loadingConcerts}
            renderItem={(item) => <ReportListItem key={item.id} {...item} />}
        />
    </>
}