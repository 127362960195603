import { Alert, Divider, PageHeader } from "antd";

export function DataExport() {
    return <>
        <PageHeader
            title={`Eksporter Data`}
        />
        <Divider orientation="left">CSV</Divider>
        <Alert type="warning" message="Husk at data må importeres og oppdateres for å være gyldig. Spesielt gjelder dette dataene fra fiken. Finansene for konserter må settes manuelt." />
        <ul style={{ marginTop: "10px" }}>
            <li><a href="https://fyk-django.fly.dev/activities/csv">Aktiviteter</a></li>
            <li><a href="https://fyk-django.fly.dev/activities/concert/financials/csv">Konsert-finanser</a></li>
            <li><a href="https://fyk-django.fly.dev/expenses/csv">Expensify utlegg</a></li>
            <li><a href="https://fyk-django.fly.dev/accounting/csv">Kjøp fra Fiken</a></li>
            <li><a href="https://fyk-django.fly.dev/settlements/transactions/csv">Transaksjoner i oppgjør</a></li>
        </ul>
    </>
}