import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";

type PermissionProviderState = {
  permissions: string[];
};

const initialState: PermissionProviderState = {
  permissions: [],
};

const PermissionsContext =
  React.createContext<PermissionProviderState>(initialState);

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function PermissionsProvider({ children }: { children: React.ReactNode }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [providerValue, setProviderValue] =
    useState<PermissionProviderState>(initialState);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      const getPermissions = async () => {
        const accessToken = await getAccessTokenSilently();
        const parsedToken = parseJwt(accessToken);
        const permissionList = parsedToken.permissions as string[];
        setProviderValue({ permissions: permissionList });
        setLoading(false);
      };
      getPermissions();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <PermissionsContext.Provider value={providerValue}>
      {loading ? null : children}
    </PermissionsContext.Provider>
  );
}

export default PermissionsProvider;

export const usePermissions = () => {
  const ctx = React.useContext(PermissionsContext);
  if (!ctx) {
    throw new Error(
      "usePermissions must be used within a PermissionsProvider component"
    );
  }

  return ctx.permissions;
};

export const usePermission = (permission: string) => {
  const ctx = React.useContext(PermissionsContext);
  if (!ctx) {
    throw new Error(
      "useHasPermission must be used within a PermissionsProvider component"
    );
  }

  return ctx.permissions.find((item) => item === permission) !== undefined;
};

export function PermissionGuard({
  permission,
  children,
}: {
  permission: string;
  children: React.ReactNode;
}) {
  const hasPermission = usePermission(permission);
  return hasPermission ? <>{children}</> : null;
}
