import { Button, Col, Divider, List, PageHeader, Row, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Concert } from "../types";
import { ReportStatus, UserExpenseReport } from "./types";
import Text from "antd/lib/typography/Text";
import { useConcerts } from "../api-hooks/api";
import { useReports } from "../api-hooks/report";

export function statusToText(status: ReportStatus) {
    switch (status) {
        case "draft":
            return <Tag color="default">Utkast</Tag>;
        case "pending":
            return <Tag color="blue">Venter på godkjenning</Tag>;
        case "approved":
            return <Tag color="green">Godkjent</Tag>;
        case "rejected":
            return <Tag color="volcano">Avvist</Tag>;
    }
}


export function ConcertInfoElement({ concert }: { concert: Concert }) {
    return (<>
        <span>{dayjs(concert.date).format("DD.MM.YYYY")}</span>
        <span> </span>
        <span>{concert.title}</span>
    </>)
}

export function ListUserReports() {

    const navigate = useNavigate();

    const { data: reports, loading: loadingReports } = useReports()
    const { data: concerts, loading: loadingConcerts } = useConcerts(null)

    const data = reports.map((item) => {

        // Hotfix due to issue with _id postfix in django rest framework
        const item2 = item as unknown as UserExpenseReport;
        return {
            ...item,
            activity: concerts.find((c) => c.id === item2.activity),
        }
    }).sort((a, b) => {
        return dayjs(b.created_at_datetime).diff(dayjs(a.created_at_datetime))
    })

    return <>
        <PageHeader
            title={`Utlegg`}
            extra={
                <Button key="1" onClick={() => navigate(`/expenses/create`)}>
                    Ny rapport
                </Button>
            }
        />
        <Divider orientation="left">Dine Rapporter</Divider>
        <List
            size="small"
            bordered
            dataSource={data}
            loading={loadingReports || loadingConcerts}
            renderItem={(item) => (
                <List.Item>
                    <Row gutter={[8, 8]}>
                        <Col xs={24}>
                            {item.activity ? <ConcertInfoElement concert={item.activity} /> : "Ukjent konsert"}
                        </Col>
                        <Col xs={24}>
                            {item.items.length} utlegg på til sammen {item.items.reduce((acc, current) => acc + current.amount, 0)} kr
                        </Col>
                        <Col xs={24}>
                            {statusToText(item.status)}
                        </Col>
                        <Col xs={24}>
                            <Button onClick={() => navigate(`/expenses/${item.id}`)}>
                                Detaljer
                            </Button>
                        </Col>
                        <Col xs={24}>
                            <Text type="secondary">Opprettet {dayjs(item.created_at_datetime).format("DD.MM.YYYY kl HH:mm")}</Text>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    </>
}