import { API_URL } from "../constants";
import { Rsvp } from "../RSVP/rsvp";
import { useApiGet, useApiGetArray, useApiPatch, useApiPost } from "./generic";
import { useMemo } from "react";

// All rsvps

export function useConcertRsvps(concertId: number) {
    const url = `${API_URL}/rsvp?concert_id=${concertId}`;
    return useApiGetArray<Rsvp>(url);
}

export function useCreateRsvp() {
    const url = `${API_URL}/rsvp`;
    return useApiPost<Rsvp>(url);
}

export function useUpdateRsvp() {
    const url = `${API_URL}/rsvp`;
    return useApiPatch<Partial<Rsvp> & { id: number; }, Rsvp>(url);
}

// User rsvps

export function useUserRsvps() {
    const url = `${API_URL}/rsvp/user`;
    return useApiGetArray<Rsvp>(url);
}

export function useUserRsvp(concertId: number) {
    const url = `${API_URL}/rsvp/user?concert_id=${concertId}`;

    const { data, ...rest } = useApiGet<Rsvp[]>(url);

    const responseStatus = useMemo(() => {
        if (data !== null) {
            if (data.length > 0) {
                const [response] = data;
                return { hasReplied: true, response: response };
            } else {
                return { hasReplied: false, response: null };
            }
        } else {
            return { hasReplied: null, response: null };
        }
    }, [data]);

    return { ...responseStatus, ...rest };

}

export function useCreateUserRsvp() {
    const url = `${API_URL}/rsvp/user`;
    return useApiPost<Rsvp, Omit<Rsvp, "id" | "person">>(url);
}

export function useUpdateUserRsvp() {
    // TODO: Note that url is inconsistent with useCreateUserRsvp and is the same as for useUpdateRsvp
    const url = `${API_URL}/rsvp`;
    return useApiPatch<Partial<Rsvp> & { id: number; }, Rsvp>(url);
}