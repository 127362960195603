import { useEffect, useState } from "react";
import { Rsvp } from "../RSVP/rsvp";
import { useConcertRsvps, useCreateRsvp, useUpdateRsvp } from "../api-hooks/rsvp";

export type PatchRsvpPayload = Partial<Rsvp> & { id: number }

export function useRsvps(concertId: number) {

    const { data: rsvps, loading, error } = useConcertRsvps(concertId)

    const [cachedRsvps, setCachedRsvps] = useState<Rsvp[]>([])

    const { execute: update } = useUpdateRsvp()
    const { execute: create } = useCreateRsvp()

    useEffect(() => {
        setCachedRsvps(rsvps)
    }, [rsvps])

    const patchRsvp = async (rsvp: PatchRsvpPayload) => {
        const response = await update(rsvp)

        if (response !== null) {
            setCachedRsvps((rsvps) => {
                return rsvps.map((rsvp) => {
                    if (rsvp.id === response.id) {
                        return response;
                    }
                    return rsvp;
                });
            })
        }

        return response
    }

    const createRsvp = async (rsvp: Omit<Rsvp, "id">) => {
        const response = await create(rsvp)

        if (response !== null) {
            setCachedRsvps((rsvps) => {
                return [...rsvps, response];
            })
        }

        return response
    }

    const upsertRsvp = async (rsvp: Partial<Rsvp>) => {
        if (rsvp.id !== undefined) {
            return patchRsvp(rsvp as PatchRsvpPayload)
        } else {
            return createRsvp(rsvp as Omit<Rsvp, "id">)
        }
    }

    return { loading, rsvps: cachedRsvps, upsertRsvp, error };
}
