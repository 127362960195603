import { Button, Divider, message, Spin, Row, Col, PageHeader, List } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ExpenseReport, ReportStatus } from "./types";
import { statusToText } from "./ListUserReports";
import Text from "antd/lib/typography/Text";
import { usePersonById } from "../PersonsProvider/PersonsProvider";
import { usePatchReportStatus, useReport } from "../api-hooks/report";
import { useConcert } from "../api-hooks/api";


type PatchReportStatus = Pick<ExpenseReport, "id" | "status">;

export function ReviewReport() {
    const { reportId } = useParams();
    const reportIdAsNumber = parseInt(reportId || "0")

    // TODO: Add loading indicator and error handling
    const { data: reportData, loading: reportLoading } = useReport(reportIdAsNumber, true)

    return (reportData !== null ? <ReviewReportView key={reportData.id} initialReport={reportData} /> : <Spin spinning={reportLoading} />)
}


function RenderActions(status: ReportStatus, setStatus: (status: ReportStatus) => void) {
    switch (status) {
        case "pending":
            return <>
                <Button type="primary" style={{ marginLeft: "5px" }} onClick={() => setStatus("approved")} >Godkjenn</Button>
                <Button type="primary" danger style={{ marginLeft: "5px" }} onClick={() => setStatus("rejected")} >Avvis</Button>
            </>
        case "rejected":
            return <>
                <Button type="primary" style={{ marginLeft: "5px" }} onClick={() => setStatus("pending")} >Trekk tilbake avvisning</Button>
            </>
        case "approved":
            return <>
                <Button type="primary" style={{ marginLeft: "5px" }} onClick={() => setStatus("pending")} >Trekk tilbake godkjenning</Button>
            </>
    }
}

function ReviewReportView({ initialReport }: { initialReport: ExpenseReport }) {

    const navigate = useNavigate()

    const [report, setReport] = useState(initialReport)
    const person = usePersonById(report.person_id)

    const { data: concert } = useConcert(report.activity_id)
    const { loading, execute } = usePatchReportStatus()

    if (concert === null) {
        return null
    }

    if (person === undefined) {
        return null
    }

    const patchReport = async (data: PatchReportStatus) => {
        const result = await execute(data)

        if (result !== null) {
            setReport(result)
            message.info("Endring gjennomført")
        }
    };

    const setStatus = async (status: ReportStatus) => {
        const data = {
            id: report.id,
            status,
        }
        patchReport(data)
    }


    return (<>
        <PageHeader
            onBack={() => navigate("/expenses/review")}
            title={`Rapport ${report.id} : ${person.first_name} ${person.last_name}`}
            tags={<>{statusToText(report.status)}</>}
        />
        <Spin spinning={loading}>
            <Divider orientation="left">Info</Divider>
            <Row gutter={[8, 8]}>
                <Col xs={24}>
                    <Text strong>Konsert:</Text> {`${dayjs(concert.date).format("DD.MM.YYYY")} - ${concert.title}`}
                </Col>
                <Col xs={24}>
                    <Text strong>Kommentar:</Text> {report.comment || "(ingen kommentar lagt inn)"}
                </Col>
            </Row>
            <Divider orientation="left">Utlegg</Divider>
            <List
                size="small"
                footer={<div>{report.items.reduce((acc, current) => acc + current.amount, 0)} kr til sammen</div>}
                bordered
                dataSource={report.items}
                renderItem={item => <List.Item>{item.description} <br /> {item.amount} kr</List.Item>}
            />
            <Divider orientation="left">Handlinger</Divider>
            <Button onClick={() => navigate("/expenses/review")}>Tilbake til oversikt</Button>
            {RenderActions(report.status, setStatus)}
        </Spin>
    </>)
}
