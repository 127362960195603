import { Form, Input, Button, Space, InputNumber, Select, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ExpenseReportItem } from "./types";

export type FormValues = {
    concertId: number,
    items: ExpenseReportItem[] | undefined,
    comment: string,
}

export function ReportFormCore({ options }: { options: { label: string, key: number }[] }) {
    return <>
        <Form.Item
            name="concertId"
            label="Konsert"
            rules={[
                {
                    required: true,
                    message: "Du må velge hvilken konsert det gjelder",
                },
            ]}
        >
            <Select>
                {options.map((o) => {
                    return <Select.Option key={o.key} value={o.key}>{o.label}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Divider orientation="left">Utlegg</Divider>
        <Form.List name="items">
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }) => (
                        <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                        >
                            <Form.Item
                                {...restField}
                                name={[name, "description"]}
                                rules={[{ required: true, message: "Beskrivelse mangler" }]}
                            >
                                <Input placeholder="Beskrivelse" />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, "amount"]}
                                rules={[{ required: true, message: "Beløp mangler" }]}
                            >
                                <InputNumber
                                    placeholder="kr"
                                    min={0.0}
                                    max={20000}
                                    controls={false}
                                />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                    ))}
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                        >
                            Legg til rad
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
        <Divider orientation="left"></Divider>
        <Form.Item name="comment" label="Kommentar">
            <Input.TextArea />
        </Form.Item>
    </>
}