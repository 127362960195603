import { Button, Form, message, PageHeader, Spin } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateConcert } from "../api-hooks/api";
import { ConcertDTO } from "../types";
import { ConcertFormCore, ConcertFormData } from "./common";

export function ConcertCreate() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data, loading, execute } = useCreateConcert()

  const createConcert = async (values: ConcertFormData) => {
    const newConcert: Omit<ConcertDTO, "id"> = {
      title: values.title,
      description: values.description,
      active: values.active,
      status: values.status,
      date: values.date.tz("Europe/Oslo").format("YYYY-MM-DD"),
    };

    execute(newConcert)
  };

  useEffect(() => {
    if (data !== null) {
      message.success("Konsert opprettet")
      navigate(`/concerts/${data.id}`)
    }
  }, [data, navigate])

  return (
    <>
      <PageHeader
        onBack={() => navigate(`/concerts}`)}
        title={`Legg til ny konsert`}
      />
      <Spin spinning={loading}>
        <Form
          form={form}
          name="edit-concert-form"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          initialValues={{ active: true }}
          layout="horizontal"
          onFinish={createConcert}
          autoComplete="off"
        >
          <ConcertFormCore />
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              loading={loading}
              style={{ marginRight: "8px" }}
              type="primary"
              htmlType="submit"
            >
              Lagre
            </Button>
            <Button
              loading={loading}
              key="1"
              onClick={() => navigate(`/concerts}`)}
            >
              Avbryt
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
}
