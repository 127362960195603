import { List, PageHeader, Switch } from "antd";
import { useEffect, useState } from "react";
import { usePersons } from "../PersonsProvider/PersonsProvider"
import { Concert } from "../types";
import { Participant } from "./Participants";
import { StatusToLabel } from "../utils";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Person } from "../Person/Persons";
import { useConcertParticipants, useEditParticipant } from "../api-hooks/api";

type PersonWithIsParticipant = Person & { isParticipant: boolean }

export type EditParticipantResponse = {
    concertId: number,
    personId: number,
    isParticipant: boolean
}

export function ParticipantsEdit({ concert }: { concert: Concert }) {
    const { execute } = useEditParticipant()
    const navigate = useNavigate()

    const { persons, loading: personsLoading } = usePersons()
    const { data: participants, loading: participantsLoading } = useConcertParticipants(concert.id)

    // Rather than using the participants from the API, we cache them locally. Alternatively we could refetch after each change.
    const [cachedParticipants, setCachedParticipants] = useState<Participant[]>([])

    const personsWithIsParticipant: PersonWithIsParticipant[] = persons.map(person => {
        return {
            ...person,
            isParticipant: cachedParticipants.find(p => p.person_id === person.id) !== undefined
        }
    })

    useEffect(() => {
        setCachedParticipants(participants)
    }, [participants])

    const changeParticipation = async (concertId: number, personId: number, isParticipant: boolean) => {
        const response = await execute({ concertId, personId, isParticipant })

        if (response !== null) {
            setCachedParticipants(participants => {
                if (response.isParticipant) {
                    return [...participants, {
                        id: 0,
                        concert_id: response.concertId,
                        person_id: response.personId,
                    }]
                } else {
                    return participants.filter(p => p.person_id !== response.personId)
                }
            })
        }
    }


    return <>
        <PageHeader
            onBack={() => navigate("../")}
            title={`${dayjs(concert.date).format("DD.MM.YYYY")} - ${concert.title}`}
            tags={<StatusToLabel status={concert.status} />}
        />
        <List
            bordered
            loading={personsLoading || participantsLoading}
            dataSource={personsWithIsParticipant}
            style={{ maxWidth: "600px" }}
            renderItem={(item) => {
                return (
                    <List.Item key={item.id}>
                        <List.Item.Meta
                            title={`${item.first_name} ${item.last_name}`}
                        />
                        <ConcertParticipationListItem concertId={concert.id} item={item} changeParticipation={changeParticipation} />
                    </List.Item>
                );
            }}
        />
    </>
}

function ConcertParticipationListItem({ concertId, item, changeParticipation }: { concertId: number, item: PersonWithIsParticipant, changeParticipation: (concertId: number, personId: number, isParticipant: boolean) => Promise<void>; }) {
    const [localLoading, setLocalLoading] = useState(false);

    return (
        <>
            <Switch
                loading={localLoading}
                checked={item.isParticipant}
                onClick={(checked) => {
                    const asyncChange = async () => {
                        setLocalLoading(true);
                        await changeParticipation(concertId, item.id, checked);
                        setLocalLoading(false);
                    };
                    asyncChange();
                }}
            />
        </>
    )
}