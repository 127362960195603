import { useAuth0 } from "@auth0/auth0-react";
import { Button, Result } from "antd";
import { usePermission } from "../PermissionsProvider";

function VerifiedGuard({ children }: { children: React.ReactNode }) {
  const isVerified = usePermission("read:own_verification");

  return <>{isVerified ? children : <NotVerifiedPage />}</>;
}

function NotVerifiedPage() {
  const { logout } = useAuth0();

  return (
    <Result
      status="403"
      title="Manual Verification Necessary to Proceed"
      subTitle="Ask Johan for manual user verification. Only members of Fagernes Yacht Klubb are allowed access to this application."
      extra={[
        <Button key="log-out" onClick={() => logout({ returnTo: window.location.origin })}>Log Out</Button>,
      ]}
    />
  );
}

export default VerifiedGuard;
