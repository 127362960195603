import { Col, Divider, Switch, List } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Concert } from "../types";
import { useAddRemoveUserConcert, useConcerts, useUserConcerts } from "../api-hooks/api";

export type UserConcertItem = { id: number }

function ConcertParticipation({
  concert,
  registered,
  loading,
  changeRegistration,
}: {
  concert: Concert;
  registered: boolean;
  loading: boolean;
  changeRegistration: (id: number, registered: boolean) => Promise<void>;
}) {
  const [localLoading, setLocalLoading] = useState(false);

  return (
    <>
      <Col>
        <span>{dayjs(concert.date).format("DD.MM.YYYY")}</span>
        <span> </span>
        <span>{concert.title}</span>
      </Col>
      <Col>
        <Switch
          loading={loading || localLoading}
          checked={registered}
          onClick={(checked) => {
            const asyncChange = async () => {
              setLocalLoading(true);
              await changeRegistration(concert.id, checked);
              setLocalLoading(false);
            };
            asyncChange();
          }}
        />
      </Col>
    </>
  );
}

function ParticipationSwitcher() {
  const [userConcertCache, setUserConcertCache] = useState<UserConcertItem[]>([]);
  const { data: concerts, loading: loadingConcerts } = useConcerts(true)
  const { data: userConcerts, loading: loadingUserConcerts } = useUserConcerts()
  const { add, remove } = useAddRemoveUserConcert()

  const loading = loadingConcerts || loadingUserConcerts;

  useEffect(() => {
    setUserConcertCache(userConcerts);
  }, [userConcerts]);

  const addUserConcert = async (id: number) => {
    const result = await add(id)
    if (result !== null) {
      setUserConcertCache((old) => [...old, result]);
    }
  }

  const removeUserConcert = async (id: number) => {
    const result = await remove(id)
    if (result !== null) {
      setUserConcertCache((old) => old.filter((item) => item.id !== id));
    }
  }

  const changeRegistration = (id: number, registered: boolean) => {
    if (registered) {
      return addUserConcert(id);
    } else {
      return removeUserConcert(id);
    }
  };

  return (
    <>
      <Divider orientation="left">Hvilke konserter har du vært med på?</Divider>
      <List
        size="large"
        bordered
        dataSource={concerts.filter((item) => {
          return dayjs(item.date) < dayjs();
        }).sort((a, b) => {
          const dateDiff = b.date.getTime() - a.date.getTime()
          return dateDiff === 0 ? a.title.localeCompare(b.title) : dateDiff
        })}
        loading={loading}
        renderItem={(item) => {
          return (
            <List.Item>
              <ConcertParticipation
                concert={item}
                loading={loadingUserConcerts}
                registered={
                  userConcertCache.filter((p) => p.id === item.id).length > 0
                }
                changeRegistration={changeRegistration}
              />
            </List.Item>
          );
        }}
      />
    </>
  );
}

export default ParticipationSwitcher;
