import { Button, Divider, PageHeader, Select, Table, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { usePersons } from "../PersonsProvider/PersonsProvider";
import { usePersonTransactions, useUserTransactions } from "../api-hooks/api";
import { useUpserContactsHook, useUpsertContactPurchasesHook } from "../api-hooks/fiken";


type FeeEntryDTO = {
    type: "group_fee" | "individual_fee";
    kind: "musicians_fee" | "management_fee"
    description: string;
    amount: number;
    concertId: number;
    date: string;
    title: string;
}

type FeeEntry = Omit<FeeEntryDTO, "date"> & { date: Date }

type PaymentEntryDTO = {
    type: "payment";
    amount: number;
    date: string;
    title: string;
    purchaseId: string,
    contactName: string,
    contactId: number,
}

type PaymentEntry = Omit<PaymentEntryDTO, "date"> & { date: Date }

type ExpenseReportEntryDTO = {
    type: "expense_report";
    amount: number;
    reportId: number;
    activityId: number;
    date: string;
    title: string;
}

type ExpenseReportEntry = Omit<ExpenseReportEntryDTO, "date"> & { date: Date }

export type InvoicingEntryDTO = FeeEntryDTO | ExpenseReportEntryDTO | PaymentEntryDTO;
export type InvoicingEntry = FeeEntry | ExpenseReportEntry | PaymentEntry


function entryToCategory(entry: InvoicingEntry) {
    switch (entry.type) {
        case "group_fee":
            return entry.kind === "musicians_fee" ? "Musikerhonorar" : "Managementhonorar";
        case "individual_fee":
            return entry.kind === "musicians_fee" ? "Musikerhonorar" : "Managementhonorar";
        case "expense_report":
            return "Utlegg";
        case "payment":
            return "Utbetaling";
    }
}

function entryToDescription(entry: InvoicingEntry) {
    switch (entry.type) {
        case "group_fee":
            return entry.description;
        case "individual_fee":
            return entry.description;
        case "expense_report":
            return "Utlegg";
        case "payment":
            return "Utbetaling";
    }
}

function entryToLink(entry: InvoicingEntry) {
    switch (entry.type) {
        case "group_fee":
            return `/concerts/${entry.concertId}`;
        case "individual_fee":
            return `/concerts/${entry.concertId}`;
        case "expense_report":
            return `/reports/${entry.reportId}`;
        case "payment":
            return `//fiken.no/foretak/tnw-as/handel/kjop/${entry.purchaseId}`;
    }
}

function entryTypeToLinkText(entryType: string) {
    switch (entryType) {
        case "group_fee":
            return "Konsert";
        case "individual_fee":
            return "Konsert";
        case "expense_report":
            return "Rapport";
        case "payment":
            return "Utbetaling";
        default:
            return entryType;
    }
}

function categoryToTag(category: string) {
    switch (category) {
        case "Musikerhonorar":
            return <Tag color="green" key={category}>{category}</Tag>;
        case "Managementhonorar":
            return <Tag color="blue" key={category}>{category}</Tag>;
        case "Utlegg":
            return <Tag color="cyan" key={category}>{category}</Tag>;
        default:
            return <Tag color="default" key={category}>{category}</Tag>;
    }
}

export function kindToName(kind: string) {
    switch (kind) {
        case "musicians_fee":
            return "Musikerhonorar";
        case "management_fee":
            return "Managementhonorar";
        default:
            return kind;
    }
}

export function kindToColor(kind: string) {
    switch (kind) {
        case "musicians_fee":
            return "green";
        case "management_fee":
            return "blue";
        default:
            return "default";
    }
}

export function kindToTag(kind: string) {
    return <Tag color={kindToColor(kind)}>{kindToName(kind)}</Tag>;
}

// TODO: Endre til konsert, type og beløp. Bruk utvalg for å velge hvilke man vil summere. Legg til link til aktuell konsert/rapport.

const columns = [
    {
        title: 'Dato',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Tittel',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: "Beskrivelse",
        dataIndex: "description",
        key: "description",
    },
    {
        title: 'Beløp (NOK)',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Kategori',
        key: 'category',
        render: (_: any, record: any) => (<>
            {categoryToTag(record.category)}
        </>),
    },
    {
        title: 'Detaljer',
        key: 'details',
        render: (_: any, record: any) => (
            <Link to={record.link}>{entryTypeToLinkText(record.type)}</Link>
        )
    },
];

const summaryColumns = [
    {
        title: 'Kategori',
        key: 'category',
        render: (_: any, record: any) => (<>
            {categoryToTag(record.category)}
        </>),
    },
    {
        title: "Antall",
        dataIndex: "count",
        key: "count",
    },
    {
        title: 'Beløp (NOK)',
        dataIndex: 'amount',
        key: 'amount',
    },
]


export function UserInvoicingOverview() {
    const navigate = useNavigate();
    const { data: invoicingEntries, loading } = useUserTransactions()

    return <>
        <PageHeader
            onBack={() => navigate("/")}
            title={"Faktureringsoversikt"}
        />
        <InvoicingTable invoicingEntries={invoicingEntries} loading={loading} />
    </>
}

export function PersonInvoicingOverview({ personId }: { personId: number }) {
    const { data: invoicingEntries, loading } = usePersonTransactions(personId)

    return <>
        <InvoicingTable invoicingEntries={invoicingEntries} loading={loading} />
    </>
}

function UpdateFikenContactsButton() {
    const { data, error, loading, refetch } = useUpserContactsHook();

    const onClick = () => {
        refetch();
    }

    useEffect(() => {
        if (data) {
            message.success("Oppdaterte Fiken-kontakter");
        }
    }, [data])

    useEffect(() => {
        if (error) {
            message.error("Kunne ikke oppdatere Fiken-kontakter");
        }
    }, [error])

    return <Button onClick={onClick} loading={loading}>Oppdater Fiken-kontakter</Button>
}

function UpdateFikenContactPurchasesButton() {
    const { data, error, loading, refetch } = useUpsertContactPurchasesHook();

    const onClick = () => {
        refetch();
    }

    useEffect(() => {
        if (data) {
            message.success("Oppdaterte Fiken-kontaktkjøp");
        }
    }, [data])

    useEffect(() => {
        if (error) {
            message.error("Kunne ikke oppdatere Fiken-kontaktkjøp");
        }
    }, [error])


    return <Button onClick={onClick} loading={loading}>Oppdater Fiken-kontaktkjøp</Button>
}


export function PersonInvoincingContainer() {
    const navigate = useNavigate();
    const { persons } = usePersons();

    const [personId, setPersonId] = useState<number | undefined>(undefined);

    return <>
        <PageHeader
            onBack={() => navigate("/")}
            title={"Faktureringsoversikt per person"}
            extra={[
                <UpdateFikenContactsButton key="update-fiken-contacts" />,
                <UpdateFikenContactPurchasesButton key="update-fiken-contact-purchases" />,
            ]}
        />
        <Select
            style={{ width: 200 }}
            options={persons.filter(person => !person.external).map((person) => ({ label: `${person.first_name} ${person.last_name}`, value: person.id }))}
            onChange={(value) => {
                setPersonId(value as number);
            }}
        />
        {personId !== undefined && <PersonInvoicingOverview key={personId} personId={personId} />}
    </>
}

function InvoicingTable({ invoicingEntries, loading }: { invoicingEntries: InvoicingEntry[], loading: boolean }) {

    const [subSelectionIdxs, setSubselectionIdxs] = useState<number[]>([]);

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            setSubselectionIdxs(selectedRowKeys as number[]);
        },
    };

    const subselection = invoicingEntries.filter((_, idx) => subSelectionIdxs.includes(idx)).reduce<{ key: string, category: string, count: number, amount: number }[]>((acc, entry) => {
        const category = entryToCategory(entry);
        const amount = entry.amount;

        if (acc.length === 0) {
            acc.push({ key: "total", category: "Totalt", amount: 0, count: 0 });
        }

        acc[0].amount += amount;
        acc[0].count += 1;

        const existing = acc.find(item => item.category === category);
        if (existing) {
            existing.amount += amount;
            existing.count += 1;
        } else {
            acc.push({
                key: category,
                category,
                amount,
                count: 1,
            });
        }
        return acc;
    }, []).reverse();

    return <>
        <Divider orientation="left">Transaksjoner</Divider>
        <Table pagination={false} loading={loading} columns={columns} rowSelection={rowSelection} dataSource={invoicingEntries.map((item, idx) => {
            return {
                key: idx,
                date: dayjs(item.date).format("DD.MM.YYYY"),
                title: `${item.title}`,
                category: entryToCategory(item),
                description: entryToDescription(item),
                amount: item.amount,
                link: entryToLink(item),
                type: item.type,
            }
        })} />
        <Divider orientation="left">Oppsummering</Divider>
        <p>Velg linjer fra transaksjonene over for å summere opp. </p>
        <Table columns={summaryColumns} dataSource={subselection} />
    </>
}