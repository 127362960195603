import { Button, Col, List, Row, Spin, Tag } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { useNavigate } from "react-router-dom";
import { usePersons } from "../PersonsProvider/PersonsProvider";
import { useConcertRsvps, useUserRsvp } from "../api-hooks/rsvp";

export type RsvpResponse = "yes" | "no";

export type Rsvp = {
  id: number;
  concert: number;
  person: number;
  response: RsvpResponse;
  comment: string;
};

type RsvpWithName = Rsvp & { firstName: string, lastName: string }

export function rsvpReponseToSpan(response: RsvpResponse) {
  switch (response) {
    case "yes":
      return (
        <>
          <span>ja</span>
        </>
      );
    case "no":
      return (
        <>
          <span>nei</span>
        </>
      );
  }
}

export function rsvpReponseToTag(response: RsvpResponse) {
  switch (response) {
    case "yes":
      return <Tag color={rsvpReponseToTagColor(response)}>Ja</Tag>;
    case "no":
      return <Tag color={rsvpReponseToTagColor(response)}>Nei</Tag>;
  }
}

export function rsvpReponseToTagColor(response: RsvpResponse) {
  switch (response) {
    case "yes":
      return "geekblue";
    case "no":
      return "volcano";
  }
}

export function ConcertRsvpList({ concertId }: { concertId: number }) {
  const { persons } = usePersons();
  const { data, loading } = useConcertRsvps(concertId)

  const rsvps: RsvpWithName[] = data.map(r => {
    const person = persons.find((person) => person.id === r.person)
    return {
      ...r,
      firstName: person?.first_name || "<first name missing>",
      lastName: person?.last_name || "<last name missing>"
    }
  })

  return (
    <>
      <List
        size="default"
        bordered
        dataSource={rsvps.sort((a, b) => a.firstName.localeCompare(b.firstName)).sort((a, b) => b.response.localeCompare(a.response))}
        loading={loading}
        renderItem={(item) => {
          return (
            <List.Item key={item.id}>
              {rsvpReponseToTag(item.response)}
              <List.Item.Meta
                title={`${item.firstName} ${item.lastName}`}
                description={item.comment}
              />
            </List.Item>
          );
        }}
      />
    </>
  );
}

export function UserRsvpInfo({ concertId }: { concertId: number }) {
  const { loading, hasReplied, response } = useUserRsvp(concertId);

  return (
    <Spin spinning={loading}>
      <Paragraph>
        {loading && "Sjekker ditt svar"}
        {hasReplied === false && <AddUserRsvp />}
        {hasReplied === true && response !== null && (
          <ShowUserRsvp rsvp={response} />
        )}
      </Paragraph>
    </Spin>
  );
}

function AddUserRsvp() {
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <Col flex="auto">
          <p>Du har ikke svart</p>
        </Col>
        <Col>
          <Button onClick={() => navigate("rsvp")}>
            Trykk her for å svare
          </Button>
        </Col>
      </Row>
    </>
  );
}

function ShowUserRsvp({ rsvp }: { rsvp: Rsvp }) {
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <Col flex="auto">
          <p>
            Du har svart {rsvpReponseToSpan(rsvp.response)},{" "}
            {rsvp.comment ? "med" : "uten"} kommentarer.
          </p>
        </Col>
        <Col>
          <Button onClick={() => navigate("rsvp")}>
            Trykk her for å endre svaret
          </Button>
        </Col>
      </Row>
    </>
  );
}
