import { ConcertStatus } from "./types";
import { Tag } from "antd";

export function StatusToLabel({ status }: { status: ConcertStatus }) {
  switch (status) {
    case "negotiation":
      return <Tag color="blue">Forhandlinger</Tag>;
    case "rejected":
      return <Tag color="orange">Utgikk</Tag>;
    case "cancelled":
      return <Tag color="red">Avlyst</Tag>;
    case "confirmed":
      return <Tag color="green">Bekreftet</Tag>;
  }
}
