import { Alert, Button, Divider, List } from "antd";
import { useUserSettlements } from "../api-hooks/api";
import { BACKEND_URL } from "../constants";

export type UserSettlementDTO = {
  id: number;
  activity_group: {
    id: number;
    title: string;
    description: string;
  };
  token: string,
  created_at: string;
};

export function SettlementsList() {
  const { data, loading } = useUserSettlements();

  // IF YOU WANT TO REFRESH THE TOKENS, UNCOMMENT THIS
  // useEffect(() => {
  //   // The file download tokens expire after 10 minutes. Refetch well before that.
  //   const interval = setInterval(() => refetch(), 1000 * 60 * 7);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [refetch]);

  return (
    <>
      <Alert
        style={{ marginBottom: "10px" }}
        message="Det er ikke lenger mulig å laste ned gamle oppgjør - ta kontakt med Johan om du trenger disse."
        type="error"
        showIcon
      />
      <Divider orientation="left">Dine oppgjør</Divider>
      <List
        size="large"
        bordered
        dataSource={data.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )}
        loading={loading}
        renderItem={(item) => {
          return (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={`${item.activity_group.title}`}
                description={item.activity_group.description}
              />
              <Button
                type="link"
                target="_blank"
                disabled
                href={`${BACKEND_URL}/settlements/settlement/${item.id}/pdf-with-token?token=${item.token}`}
              >
                Last ned oppgjør
              </Button>
            </List.Item>
          );
        }}
      />
    </>
  );
}
