import {
  Alert,
  Button,
  Divider,
  PageHeader,
  Radio,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Rsvp, RsvpResponse } from "../RSVP/rsvp";
import { Concert } from "../types";
import { StatusToLabel } from "../utils";
import { Form } from "antd";

import TextArea from "antd/lib/input/TextArea";
import { useCreateUserRsvp, useUpdateUserRsvp, useUserRsvp } from "../api-hooks/rsvp";

export function ConcertRsvp({ concert }: { concert: Concert }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [initialValue, setInitialValue] = useState<
    { comment: string; response: RsvpResponse } | undefined
  >(undefined);

  const { loading: loadingCurrentRsvp, hasReplied, response: currentRsvp } = useUserRsvp(concert.id);
  const { loading: loadingUpdate, execute: executeUpdate } = useUpdateUserRsvp();
  const { loading: loadingCreate, execute: executeCreate } = useCreateUserRsvp();

  const loading = loadingCurrentRsvp || loadingUpdate || loadingCreate;

  useEffect(() => {
    if (currentRsvp !== null) {
      setInitialValue({ comment: currentRsvp.comment, response: currentRsvp.response });
    }
  }, [currentRsvp])

  useEffect(() => {
    form.resetFields();
  }, [initialValue, form]);

  const upsertRsvp = async (rsvp: Pick<Rsvp, "response" | "comment">) => {
    let ok = false;

    if (currentRsvp !== null) {
      const result = await executeUpdate({
        id: currentRsvp.id,
        ...rsvp,
      });
      ok = result !== null;
    } else {
      const result = await executeCreate({
        concert: concert.id,
        ...rsvp,
      });

      ok = result !== null;
    }

    if (ok) {
      navigate("../");
    }
  }

  return (
    <>
      <PageHeader
        onBack={() => navigate("../")}
        title={`${dayjs(concert.date).format("DD.MM.YYYY")} - ${concert.title}`}
        tags={<StatusToLabel status={concert.status} />}
      />
      <>
        <Spin spinning={loading}>
          <Divider orientation="left">Kan du være med?</Divider>
          {hasReplied === true && (
            <Alert
              showIcon
              style={{ marginBottom: "12px" }}
              message="Du har svart tidligere. Send inn på nytt om du vil oppdatere ja/nei eller kommentaren."
              type="info"
            />
          )}
          <Form
            form={form}
            name="concert-rsvp-form"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={initialValue}
            layout="horizontal"
            onFinish={(item) => upsertRsvp(item)}
            autoComplete="off"
          >
            <Form.Item label="Kommentar" name="comment">
              <TextArea placeholder="Legg inn tillegsinfo her. E.g. jeg må hjem før kl X." />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Vær grei å velg om du kan være med eller ikke",
                },
              ]}
              name="response"
              label="Ditt svar"
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="yes">Ja - jeg kan være med</Radio>
                  <Radio value="no">Nei - jeg kan ikke være med</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <Button
                style={{ marginRight: "8px" }}
                type="primary"
                htmlType="submit"
              >
                {hasReplied ? "Oppdater svar" : "Send inn svar"}
              </Button>
              <Button key="1" onClick={() => navigate("../")}>
                Avbryt
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </>
    </>
  );
}
