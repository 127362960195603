import { Button, Checkbox, Col, Divider, Form, Input, InputNumber, List, message, Modal, Row, Select, Tag } from "antd";
import { useState } from "react";
import { Concert } from "../types";
import { kindToTag } from "../Invoicing/UserInvoicing";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useConcertFees, useCreateConcertFee, useDeleteConcertFee, useEditConcertFee } from "../api-hooks/fee";

export type ConcertFee = {
    id: number;
    concert: number;
    kind: "musicians_fee" | "management_fee";
    amount: number;
    description: string;
    published: boolean;
}

function FeeFormCore() {
    return <>
        <Form.Item name="kind" label="Type" rules={[{ required: true }]}>
            <Select>
                <Select.Option value="musicians_fee">Musikerhonorar</Select.Option>
                <Select.Option value="management_fee">Managementhonorar</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item
            label="Beskrivelse"
            name="description"
            rules={[{ required: true, message: 'Beskrivelse er obligatorisk' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Beløp"
            name="amount"
            rules={[{ required: true, message: "Beløp mangler" }]}
        >
            <InputNumber
                placeholder="kr"
                min={0.0}
                max={20000}
                controls={false}
            />
        </Form.Item>
        <Form.Item name="published" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox>Publiser</Checkbox>
        </Form.Item>
    </>
}


function AddFeeModal({ concert, onClose }: { concert: Concert, onClose: (created: boolean) => void }) {
    const [form] = Form.useForm();
    const { loading, execute } = useCreateConcertFee()

    const onFinish = async (values: any) => {
        const payload = {
            concert: concert.id,
            kind: values.kind,
            amount: values.amount,
            description: values.description,
            published: values.published
        }
        const result = await execute(payload)

        if (result !== null) {
            onClose(true)
            message.success("Honorar lagt til")
        }
    }

    return <>
        <Modal
            open
            destroyOnClose
            confirmLoading={loading}
            cancelButtonProps={{ loading: loading }}
            okButtonProps={{ loading: loading }}
            title="Legg til honorar"
            onOk={() => {
                form.submit()
            }}
            onCancel={() => {
                if (!loading) {
                    form.resetFields();
                    onClose(false);
                } else {
                    message.error("Vent til operasjonen er ferdig");
                }
            }}
        >
            <Form name="basic"
                preserve={false}
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                autoComplete="off">
                <FeeFormCore />
            </Form>
        </Modal>
    </>
}

function EditFeeModal({ concert, fee, onClose }: { concert: Concert, fee: ConcertFee, onClose: (updated: boolean) => void }) {
    const [form] = Form.useForm();
    const { loading, execute } = useEditConcertFee()

    const onFinish = async (values: any) => {
        const payload = {
            id: fee.id,
            concert: concert.id,
            kind: values.kind,
            amount: values.amount,
            description: values.description,
            published: values.published
        }
        const result = await execute(payload)

        if (result !== null) {
            onClose(true)
            message.success("Honorar endret")
        }
    }

    return <>
        <Modal
            open
            destroyOnClose
            confirmLoading={loading}
            cancelButtonProps={{ loading: loading }}
            okButtonProps={{ loading: loading }}
            title="Endre honorar"
            onOk={() => {
                form.submit()
            }}
            onCancel={() => {
                if (!loading) {
                    form.resetFields();
                    onClose(false);
                } else {
                    message.error("Vent til operasjonen er ferdig");
                }
            }}
        >
            <Form name="basic"
                preserve={false}
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                    kind: fee.kind,
                    amount: fee.amount,
                    description: fee.description,
                    published: fee.published
                }}
                onFinish={onFinish}
                autoComplete="off">
                <FeeFormCore />
            </Form>
        </Modal>
    </>
}


function DeleteFeeModal({ fee, onClose }: { fee: ConcertFee, onClose: (deleted: boolean) => void }) {
    const { loading, execute } = useDeleteConcertFee(fee.id)

    const onOk = async () => {
        const result = await execute();
        if (result) {
            message.success(`Honorar slettet`);
            onClose(true);
        }
    }

    return (<>
        <Modal
            open
            destroyOnClose
            title="Slett honorar"
            confirmLoading={loading}
            cancelButtonProps={{ disabled: loading }}
            okButtonProps={{ danger: true, loading, disabled: loading }}
            onOk={onOk} onCancel={() => onClose(false)}>
            <p>Er du sikker på at du vil slette honoraret?</p>
            <>
                <p>{kindToTag(fee.kind)}<Tag hidden={fee.published}>Ikke publisert</Tag></p>
                <p>{fee.description}</p>
                <p>{fee.amount} kr </p>
            </>
        </Modal>
    </>)
}

export function ConcertFees({ concert }: { concert: Concert }) {
    const [addFee, setAddFee] = useState(false);
    const [editFee, setEditFee] = useState<ConcertFee | undefined>(undefined);
    const [deleteFeeModal, setDeleteFeeModal] = useState<ConcertFee | undefined>(undefined);
    const { data: concertFees, loading, refetch } = useConcertFees(concert.id);

    return <>
        <Divider orientation="left">Honorarer</Divider>
        <Row>
            <Col flex="auto">
                <p>Gruppehonorarer. Musikerhonorarer til alle faste FYK'ere som deltok på konserten. Managementhonorar til management.</p>
            </Col>
            <Col>
                <Button style={{ marginBottom: "1em" }} icon={<PlusOutlined />} onClick={() => setAddFee(true)}>Legg til honorar</Button>
            </Col>
        </Row>
        <List
            size="large"
            bordered
            dataSource={concertFees.sort((a, b) => a.id - b.id)}
            loading={loading}
            renderItem={(item) => <List.Item key={item.id}>
                <List.Item.Meta
                    title={<>{kindToTag(item.kind)}<Tag hidden={item.published}>Ikke publisert</Tag> {item.description}</>}
                />
                <div>{item.amount} kr <Button type="text" style={{ marginLeft: "1em" }} icon={<EditOutlined />} onClick={() => setEditFee(item)} />
                    <Button type="text" style={{ marginLeft: "0em" }} icon={<DeleteOutlined />} onClick={() => setDeleteFeeModal(item)} /></div>
            </List.Item>}
        />
        {addFee && <AddFeeModal concert={concert} onClose={(created) => {
            if (created) {
                refetch();
            }
            setAddFee(false)
        }} />}
        {editFee !== undefined && <EditFeeModal concert={concert} fee={editFee} onClose={(updated) => {
            if (updated) {
                refetch();
            }
            setEditFee(undefined)
        }} />}
        {deleteFeeModal !== undefined && <DeleteFeeModal fee={deleteFeeModal} onClose={(deleted) => {
            if (deleted) {
                refetch();
            }
            setDeleteFeeModal(undefined)
        }} />}
    </>
}