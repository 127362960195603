import { Button, Divider, PageHeader, Spin } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";
import dayjs from "dayjs";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { PermissionGuard, usePermission } from "../PermissionsProvider";
import { ConcertRsvp } from "../RSVP/ConcertRsvp";
import { ConcertRsvpList, UserRsvpInfo } from "../RSVP/rsvp";
import { Concert } from "../types";
import { StatusToLabel } from "../utils";
import { CheckOutlined, LinkOutlined } from "@ant-design/icons";
import { ParticipantsList } from "./Participants";
import { RsvpOnBehalfOf } from "./RsvpOnBehalfOf";
import { ParticipantsEdit } from "./ParticipantsEdit";
import { ConcertFees } from "./ConcertFees";
import { useConcert } from "../api-hooks/api";

function ConcertView() {
  const { concertId: concertIdParam } = useParams();
  const concertId = parseInt(concertIdParam || "-1")
  const { data: concert, loading } = useConcert(concertId)

  if (loading || concert === null) {
    // TODO: This looks ugly
    return (
      <Spin size="large">
        <Paragraph></Paragraph>
      </Spin>
    );
  }

  return (
    <>
      <Routes>
        <Route path="rsvp" element={<ConcertRsvp concert={concert} />} />
        <Route path="rsvp-on-behalf-of" element={<RsvpOnBehalfOf concert={concert} />} />
        <Route path="edit-participants" element={<ParticipantsEdit concert={concert} />} />
        <Route path="/*" element={<ConcertOverview concert={concert} />} />
      </Routes>
    </>
  );
}

export default ConcertView;

function ConcertOverview({ concert }: { concert: Concert }) {
  const navigate = useNavigate();
  const canCreateConcerts = usePermission("write:concerts");

  return (
    <>
      <PageHeader
        onBack={() => navigate("../../")}
        title={`${dayjs(concert.date).format("DD.MM.YYYY")} - ${concert.title}`}
        tags={<StatusToLabel status={concert.status} />}
        extra={
          canCreateConcerts ? (
            <Button
              key="1"
              onClick={() => navigate(`/concerts/${concert.id}/edit`)}
            >
              Endre
            </Button>
          ) : null
        }
      />
      <Divider orientation="left">Info</Divider>
      <Paragraph>
        {concert.description || <Text italic>Ingen info lagt inn.</Text>}
      </Paragraph>
      <Divider orientation="left">Svar</Divider>
      <UserRsvpInfo concertId={concert.id} />
      <ConcertRsvpList concertId={concert.id} />
      <Paragraph style={{ marginTop: "8px" }}>
        <Text
          copyable={{
            text: `${window.location.origin}/concerts/${concert.id}/rsvp`,
            icon: [
              <LinkOutlined key="copy-icon" />,
              <CheckOutlined key="copied-icon" />,
            ],
          }}
          type="secondary"
        >
          RSVP Link
        </Text>
        <PermissionGuard permission="write:rsvp">
          <Button type="link" onClick={() => navigate("rsvp-on-behalf-of")}>
            Svar på vegne av andre
          </Button>
        </PermissionGuard>
      </Paragraph>
      <Divider orientation="left">Deltagere</Divider>
      <Paragraph>
        Liste over de som deltok på konserten. Oppdateres etter konserten.
      </Paragraph>
      <ParticipantsList concertId={concert.id} />
      <PermissionGuard permission="write:rsvp">
        <Button type="link" onClick={() => navigate("edit-participants")}>
          Endre deltakere
        </Button>
      </PermissionGuard>
      <PermissionGuard permission="write:concerts">
        <ConcertFees concert={concert} />
      </PermissionGuard>
    </>
  );
}
