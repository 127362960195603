import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import { message } from "antd";

interface ResourceWithId {
    id: number;
}


function createHeaders(accessToken: string) {
    return {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
    };
}


export function useApiGet<OutputResource>(url: string, fetchByDefault = true) {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState<OutputResource | null>(null);
    const [error, setError] = useState<unknown | null>(null);
    const [loading, setLoading] = useState(false);
    const [doFetch, setDoFetch] = useState(fetchByDefault);

    useEffect(() => {
        if (!doFetch) {
            return;
        }

        const fetchData = async (url: string) => {
            setLoading(true);
            try {
                const accessToken = await getAccessTokenSilently();
                const response = await fetch(url, {
                    method: "GET",
                    headers: createHeaders(accessToken),
                });

                if (!response.ok) {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }

                const json = await response.json() as OutputResource;

                setData(json);
            } catch (e: any) {
                setError(e);
                Sentry.captureException(e);
                message.error(
                    "Innlasting data feilet - prøv igjen og ta kontakt med support hvis det vedvarer"
                );
            } finally {
                setLoading(false);
            }
        }
        fetchData(url);
        setDoFetch(false);
    }, [doFetch, url, getAccessTokenSilently]);

    const refetch = () => {
        setDoFetch(true);
    };

    return { data, error, loading, refetch };
}

export function useApiGetArray<OutputResource>(url: string) {
    const { data, ...rest } = useApiGet<OutputResource[]>(url);

    const dataWithEmptyArrayAsDefault = useMemo(() => {
        if (data === null) {
            return [];
        }
        return data;
    }, [data]);

    return { data: dataWithEmptyArrayAsDefault, ...rest };
}


export function useApiPost<OutputResource, InputResource = Omit<OutputResource, "id">>(url: string) {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState<OutputResource | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);

    const execute = async (resource: InputResource) => {
        setLoading(true);
        let responseData: OutputResource | null = null;
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(resource),
                headers: createHeaders(accessToken),
            });

            if (!response.ok) {
                throw new Error(`${response.status}: ${response.statusText}`);
            }

            const json = await response.json() as OutputResource;

            setData(json);
            responseData = json;
        } catch (e: any) {
            setError(e);
            Sentry.captureException(e);
            message.error(
                "Opprettelse av ressurs feilet - prøv igjen og ta kontakt med support hvis det vedvarer"
            );
        } finally {
            setLoading(false);
        }
        return responseData;
    };

    return { data, error, loading, execute };
}

export function useApiPatch<InputResource extends ResourceWithId, OutputResource = InputResource>(url: string, useIdFromResource: boolean = true) {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState<OutputResource | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);

    const execute = async (resource: InputResource) => {
        setLoading(true);
        let responseData: OutputResource | null = null;

        try {
            const urlWithId = useIdFromResource ? `${url}/${resource.id}` : url;
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(urlWithId, {
                method: "PATCH",
                body: JSON.stringify(resource),
                headers: createHeaders(accessToken),
            });

            if (!response.ok) {
                throw new Error(`${response.status}: ${response.statusText}`);
            }

            const json = await response.json() as OutputResource;

            setData(json);
            responseData = json;
        } catch (e: any) {
            setError(e);
            Sentry.captureException(e);
            message.error(
                "Endring av ressurs feilet - prøv igjen og ta kontakt med support hvis det vedvarer"
            );
        } finally {
            setLoading(false);
        }

        return responseData;
    };

    return { data, error, loading, execute };
}


export function useApiDelete(url: string) {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState<true | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);

    const execute = async () => {
        setLoading(true);
        let deleted: true | null = null;
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(url, {
                method: "DELETE",
                headers: createHeaders(accessToken),
            });

            if (!response.ok) {
                throw new Error(`${response.status}: ${response.statusText}`);
            }

            deleted = true
            setData(deleted);
        } catch (e: any) {
            setError(e);
            Sentry.captureException(e);
            message.error(
                "Sletting av ressurs feilet - prøv igjen og ta kontakt med support hvis det vedvarer"
            );
        } finally {
            setLoading(false);
        }
        return deleted;
    };

    return { data, error, loading, execute };
}

/* To be used for adding and removing elements from a set. This is an uncommon pattern, and should be used with care.
- The set is identified by the url 
- The element to be added or removed is identified by an id 
- The element is added with a POST request and removed with a DELETE request
- In both cases, the API is assumed to return the added or removed element
*/
export function useApiAddRemoveId<Response>(url: string) {

    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);

    const execute = async (id: string | number, method: "POST" | "DELETE") => {
        setLoading(true);
        let responseData: Response | null = null;
        try {
            const accessToken = await getAccessTokenSilently();
            const urlWithId = `${url}/${id}`;
            const response = await fetch(urlWithId, {
                method: method,
                headers: createHeaders(accessToken),
            });

            if (!response.ok) {
                throw new Error(`${response.status}: ${response.statusText}`);
            }

            responseData = await response.json() as Response;
        } catch (e: any) {
            setError(e);
            Sentry.captureException(e);
            message.error(
                "Endring av ressurs feilet - prøv igjen og ta kontakt med support hvis det vedvarer"
            );
        } finally {
            setLoading(false);
        }

        return responseData;
    };

    const add = async (id: string | number) => execute(id, "POST");
    const remove = async (id: string | number) => execute(id, "DELETE");

    return { error, loading, add, remove };

}