import { Button, Form, message, PageHeader, Spin } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConcertDTO } from "../types";
import { ConcertFormCore } from "./common";
import { useConcert, useUpdateConcert } from "../api-hooks/api";

type ConcertFormData = {
  title: string;
  description: string;
  date: moment.Moment;
  status: "confirmed"; // TODO
  active: boolean;
};

function ConcertEdit() {
  const { concertId: concertIdParam } = useParams();
  const concertId = parseInt(concertIdParam || "");

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<Partial<ConcertFormData>>(
    {}
  );

  const { data: concert, loading: getLoading } = useConcert(concertId);
  const { data: updatedConcert, loading: updateLoading, execute: executeUpdate } = useUpdateConcert()

  const loading = getLoading || updateLoading;

  useEffect(() => {
    if (concert !== null) {
      const concertWithProperDate = {
        ...concert,
        date: moment(concert.date),
      } as ConcertFormData;
      setInitialValues(concertWithProperDate);
    }
  }, [concert]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form])

  useEffect(() => {
    if (updatedConcert !== null) {
      message.success("Konsert oppdatert");
      navigate(`/concerts/${updatedConcert.id}`);
    }
  }, [updatedConcert, navigate])

  const onFinish = (values: ConcertFormData) => {
    const patchedConcert: ConcertDTO = {
      id: concertId,
      title: values.title,
      description: values.description,
      status: values.status,
      active: values.active,
      date: values.date.tz("Europe/Oslo").format("YYYY-MM-DD"),
    };
    executeUpdate(patchedConcert);
  };

  return (
    <>
      <PageHeader
        onBack={() => navigate(`/concerts/${concertId}`)}
        title={`Endre konsert`}
      />
      <Spin spinning={loading}>
        <Form
          form={form}
          name="edit-concert-form"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          initialValues={initialValues}
          layout="horizontal"
          onFinish={onFinish}
          autoComplete="off"
        >
          <ConcertFormCore />
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              style={{ marginRight: "8px" }}
              type="primary"
              htmlType="submit"
            >
              Lagre
            </Button>
            <Button key="1" onClick={() => navigate(`/concerts/${concertId}`)}>
              Avbryt
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
}

export default ConcertEdit;
