import { Col, Divider, List, Button, Tag, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Concert } from "../types";
import { Rsvp, rsvpReponseToTagColor, RsvpResponse } from "./rsvp";
import { useNavigate } from "react-router-dom";
import { useConcerts } from "../api-hooks/api";
import { useCreateUserRsvp, useUserRsvps } from "../api-hooks/rsvp";

function userRsvpReponseToTag(response: RsvpResponse) {
  switch (response) {
    case "yes":
      return <Tag color={rsvpReponseToTagColor(response)}>Ditt svar: Ja</Tag>;
    case "no":
      return <Tag color={rsvpReponseToTagColor(response)}>Ditt svar: Nei</Tag>;
  }
}

function UserConcertRsvp({
  concert,
  loading,
  rsvp,
  postRsvp,
}: {
  concert: Concert;
  loading: boolean;
  rsvp: Rsvp | undefined;
  postRsvp: (concertId: number, rsvpResponse: RsvpResponse) => Promise<void>;
}) {
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();

  const clickHandler = async (rsvpRespose: RsvpResponse) => {
    setLocalLoading(true);
    await postRsvp(concert.id, rsvpRespose);
    setLocalLoading(false);
  };

  return (
    <>
      <Col flex="auto">
        <span>{dayjs(concert.date).format("DD.MM.YYYY")}</span>
        <span> </span>
        <span>{concert.title}</span>
      </Col>
      <Col>
        {rsvp !== undefined && (
          <>
            {userRsvpReponseToTag(rsvp.response)}
            <Button onClick={() => navigate(`/concerts/${concert.id}/rsvp`)}>
              Endre
            </Button>
          </>
        )}
        {loading === false && rsvp === undefined && (
          <>
            <Spin spinning={localLoading}>
              <Space direction="horizontal">
                <Button onClick={() => clickHandler("yes")}>Ja</Button>
                <Button onClick={() => clickHandler("no")}>Nei</Button>
                <Button
                  onClick={() => navigate(`/concerts/${concert.id}/rsvp`)}
                >
                  Svar med kommentar
                </Button>
              </Space>
            </Spin>
          </>
        )}
      </Col>
    </>
  );
}

export function UserRsvpOverview() {

  // We maintain a local cache of user RSVPs to avoid refetching after each mutation
  const [cachedRsvps, setCachedRsvps] = useState<Rsvp[]>([]);

  const { data: concerts, loading: loadingConcerts } = useConcerts(true);
  const { data: rsvps, loading: loadingRsvps } = useUserRsvps();
  const { execute: executeCreate } = useCreateUserRsvp();

  const loading = loadingConcerts || loadingRsvps;

  const createRsvp = async (concertId: number, rsvpResponse: RsvpResponse) => {
    const payload = {
      concert: concertId,
      response: rsvpResponse,
      comment: "",
    };
    const result = await executeCreate(payload);

    if (result !== null) {
      setCachedRsvps((old) => [...old, result]);
    }
  }

  useEffect(() => {
    setCachedRsvps(rsvps);
  }, [rsvps]);

  return (
    <>
      <Divider orientation="left">Kan du være med?</Divider>
      <List
        size="large"
        bordered
        dataSource={concerts.filter((item) => {
          return dayjs(item.date) > dayjs();
        })}
        loading={loading}
        renderItem={(item) => {
          return (
            <List.Item>
              <UserConcertRsvp
                concert={item}
                loading={loadingRsvps}
                rsvp={cachedRsvps.find((rsvpItem) => rsvpItem.concert === item.id)}
                postRsvp={createRsvp}
              />
            </List.Item>
          );
        }}
      />
    </>
  );
}
