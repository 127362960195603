import "./App.css";

import "moment/locale/nb";
import locale from "antd/lib/locale/nb_NO";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Layout, Menu, Button, Alert, ConfigProvider } from "antd";
import ParticipationSwitcher from "./ParticipationSwitcher";
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import SubMenu from "antd/lib/menu/SubMenu";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Concerts from "./Concerts";
import ConcertView from "./Concert/Concert";
import ConcertEdit from "./Concert/ConcertEdit";
import PermissionsProvider, { usePermission } from "./PermissionsProvider/PermissionsProvider";
import { ConcertCreate } from "./Concert";
import VerifiedGuard from "./VerifiedGuard/VerifiedGuard";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { SettlementsList } from "./Settlements/Settlements";
import { UserRsvpOverview } from "./RSVP/RsvpOverview";
import { Persons } from "./Person/Persons";
import { API_URL } from "./constants";
import { PersonsProvider } from "./PersonsProvider/PersonsProvider";
import { ListUserReports } from "./Expenses/ListUserReports";
import { CreateReport } from "./Expenses/CreateReport";
import { ReportDetail } from "./Expenses/ReportDetail";
import { EditReport } from "./Expenses/EditReport";
import { ListReports } from "./Expenses/ListReports";
import { ReviewReport } from "./Expenses/ReviewReport";
import { DataExport } from "./DataExport/DataExport";
import { PersonInvoincingContainer, UserInvoicingOverview } from "./Invoicing/UserInvoicing";

const { Header, Content } = Layout;

if (!API_URL.includes("localhost")) {
  Sentry.init({
    dsn: "https://1af3769f0fbc49709b792bd561435288@o151968.ingest.sentry.io/6189223",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
} else {
  console.log("Sentry disabled as the api seemingly runs on localhost");
}

const scopes = [
  "read:own_verification",
  "read:concerts",
  "write:concerts",
  "read:rsvp",
  "write:own_rsvp ",
  "write:rsvp ",
  "read:own_settlements",
  "read:persons",
  "write:persons",
  "read:own_expense_reports",
  "write:own_expense_reports",
  "read:expense_reports",
  "write:expense_reports",
  "write:site-admin",
]

function App() {
  return (
    <Auth0Provider
      domain="johhat.eu.auth0.com"
      clientId="5z2ld2b0VT2o7QvjrdeS1XZvPdGe5mx3"
      redirectUri={window.location.origin}
      audience="https://fyk.herokuapp.com/api"
      scope={scopes.join(" ")}
      useRefreshTokens
    >
      <WrappedApp />
    </Auth0Provider>
  );
}

function WrappedApp() {
  const { isAuthenticated } = useAuth0();

  return (
    <ConfigProvider locale={locale}>
      <Router>
        {isAuthenticated ? (
          <AuthedApp />
        ) : (
          <>
            <UnAuthedApp />
          </>
        )}
      </Router>
    </ConfigProvider>
  );
}

function UnAuthedApp() {
  return (
    <>
      <Content className="login-layout">
        <Layout className="login-background">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/data-deletion"
              element={
                <>
                  <p>Contact johan.hatleskog at gmail com for data deletion.</p>
                </>
              }
            />
            <Route
              path="/debug"
              element={
                <>
                  <p>Debug intel:</p>
                  <p>
                    User agent: <code>{navigator.userAgent}</code>
                  </p>
                </>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<ProtectedDummyRoute />} />
          </Routes>
        </Layout>
      </Content>
    </>
  );
}

function Login() {
  return (
    <Layout className="login-background">
      <Content className="login-layout">
        <Title>Yachtrockweb</Title>
        <Paragraph>One of many sources to a smooth journey.</Paragraph>
        <Alert
          message="This web site is only for members of Fagernes Yacht Klubb band."
          type="warning"
        />
        <div className="site-layout-content">
          <LoginButton />
        </div>
        <p>
          <Link to="/privacy-policy">Privacy policy</Link> |{" "}
          <Link to="/data-deletion">Data deletion</Link>
        </p>
      </Content>
    </Layout>
  );
}

function AuthedApp() {
  return (
    <PermissionsProvider>
      <VerifiedGuard>
        <PersonsProvider>
          <Layout>
            <Header className="header">
              <TopMenu />
            </Header>
            <Content>
              <div className="site-layout-content">
                <Routes>
                  <Route path="/concerts" element={<Concerts />} />
                  <Route path="/concerts/create" element={<ConcertCreate />} />
                  <Route
                    path="/concerts/participation"
                    element={<ParticipationSwitcher />}
                  />
                  <Route path="/concerts/rsvp" element={<UserRsvpOverview />} />
                  <Route
                    path="/concerts/:concertId/edit"
                    element={<ConcertEdit />}
                  />
                  <Route
                    path="/concerts/:concertId/*"
                    element={<ConcertView />}
                  />
                  <Route path="/expenses" element={<ListUserReports />} />
                  <Route path="/expenses/review" element={<ListReports />} />
                  <Route path="/expenses/review/:reportId" element={<ReviewReport />} />
                  <Route path="/expenses/create" element={<CreateReport />} />
                  <Route path="/expenses/:reportId" element={<ReportDetail />} />
                  <Route path="/expenses/:reportId/edit" element={<EditReport />} />
                  <Route path="/settlements" element={<SettlementsList />} />
                  <Route path="/invoicing" element={<UserInvoicingOverview />} />
                  <Route path="/invoicing-per-person" element={<PersonInvoincingContainer />} />
                  <Route path="/data-export" element={<DataExport />} />
                  <Route
                    path="/about"
                    element={<p>This is the about path.</p>}
                  />
                  <Route path="/persons" element={<Persons />} />
                  <Route
                    path="/persons/add"
                    element={
                      <p>
                        Denne funksjonaliteten er ikke helt på plass enda. Spør
                        Johan hvis noen bør legges til :-)
                      </p>
                    }
                  />
                  <Route
                    path="/person/:personId/edit"
                    element={
                      <p>
                        Denne funksjonaliteten er ikke helt på plass enda. Spør
                        Johan hvis noe bør endres :-)
                      </p>
                    }
                  />
                  <Route
                    path="/data-deletion"
                    element={
                      <>
                        <p>
                          Contact johan.hatleskog at gmail com for data deletion.
                        </p>
                      </>
                    }
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="*" element={<Navigate to="/concerts" />} />
                </Routes>
              </div>
            </Content>
          </Layout>
        </PersonsProvider>
      </VerifiedGuard>
    </PermissionsProvider>
  );
}

function TopMenu() {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const canReviewExpenses = usePermission("write:expense_reports");
  const isAdmin = usePermission("write:site-admin")
  return <>
    <Menu
      style={{ paddingLeft: "24px", paddingRight: "24px" }}
      mode="horizontal"
    >
      <Menu.Item key={"home"} onClick={() => navigate("/")}>
        Hjem
      </Menu.Item>
      <SubMenu title="Konserter" key="concerts">
        <Menu.Item
          key={"concerts-overview"}
          onClick={() => navigate("/concerts")}
        >
          Oversikt
        </Menu.Item>
        <Menu.Item
          key="mass-register-rsvp"
          onClick={() => navigate("/concerts/rsvp")}
        >
          Registrer om du kan delta
        </Menu.Item>
        <Menu.Item
          key="mass-register-participation"
          onClick={() => navigate("/concerts/participation")}
        >
          Registrer om du har deltatt
        </Menu.Item>
      </SubMenu>
      <Menu.Item
        key={"expenses"}
        onClick={() => navigate("/expenses")}
      >
        Utlegg
      </Menu.Item>
      <Menu.Item
        key={"settlements"}
        onClick={() => navigate("/invoicing")}
      >
        Fakturering
      </Menu.Item>
      <SubMenu title="Annet" key="settings">
        <Menu.Item key="persons" onClick={() => navigate("/persons")}>
          Personer
        </Menu.Item>
        <Menu.Item hidden={!canReviewExpenses} key="expense-review" onClick={() => navigate("/expenses/review")}>
          Godkjenning av utlegg
        </Menu.Item>
        <Menu.Item hidden={!canReviewExpenses} key="invoicing-per-person" onClick={() => navigate("/invoicing-per-person")}>
          Faktureringsoversikt
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="privacy-policy"
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </Menu.Item>
        <Menu.Item
          key="data-deletion"
          onClick={() => navigate("/data-deletion")}
        >
          Data Deletion
        </Menu.Item>
        <Menu.Divider hidden={!isAdmin} />
        <Menu.Item hidden={!isAdmin}
          key="admin-page"
          onClick={() => window.open("https://fyk-django.fly.dev/admin/", "_blank")}
        >
          Django admin
        </Menu.Item>
        <Menu.Item hidden={!isAdmin}
          key="fly-io"
          onClick={() => window.open("https://fly.io/dashboard", "_blank")}
        >
          Fly.io
        </Menu.Item>
        <Menu.Item hidden={!isAdmin}
          key="sentry"
          onClick={() => window.open("https://sentry.io/organizations/johan-hatleskog/", "_blank")}
        >
          Sentry
        </Menu.Item>
        <Menu.Item hidden={!isAdmin}
          key="auth0"
          onClick={() => window.open("https://manage.auth0.com/login", "_blank")}
        >
          Auth0
        </Menu.Item>
        <Menu.Item hidden={!isAdmin}
          key="data-export"
          onClick={() => navigate("/data-export")}
        >
          Data-eksport
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="log-out"
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          Log ut
        </Menu.Item>
      </SubMenu>
    </Menu></>
}


const LoginButton = () => {
  const { loginWithRedirect, isLoading } = useAuth0();

  return (
    <Button
      type="primary"
      size="large"
      loading={isLoading}
      onClick={loginWithRedirect}
    >
      Log In
    </Button>
  );
};

const ProtectedDummyRoute = withAuthenticationRequired(() => (
  <>Redirecting to login</>
));

export default App;
