import { API_URL } from "../constants";
import { Concert, ConcertDTO } from "../types";
import { Participant } from "../Concert/Participants";
import { Person } from "../Person/Persons";
import { useApiAddRemoveId, useApiGet, useApiGetArray, useApiPatch, useApiPost } from "./generic";
import { useMemo } from "react";
import { EditParticipantResponse } from "../Concert/ParticipantsEdit";
import { InvoicingEntry, InvoicingEntryDTO } from "../Invoicing/UserInvoicing";
import { UserSettlementDTO } from "../Settlements/Settlements";
import { UserConcertItem } from "../ParticipationSwitcher/ParticipationSwitcher";



export function useConcerts(active: boolean | null = true) {
    const url = `${API_URL}/concerts`
    const urlWithFilter = active !== null ? `${url}?active=${active}` : url

    const { data, ...rest } = useApiGet<ConcertDTO[]>(urlWithFilter)

    const transformedData = useMemo(() => {
        if (data !== null) {
            return data.map((item) => {
                return {
                    ...item,
                    date: new Date(item.date),
                };
            }).sort(
                (a: { date: Date }, b: { date: Date }) => {
                    return a.date.getTime() - b.date.getTime();
                }
            ) as Concert[];;
        }
        return [];
    }, [data])

    return { data: transformedData, ...rest }
}

export function useConcert(id: number) {
    const url = `${API_URL}/concerts/${id}`
    const { data, ...rest } = useApiGet<ConcertDTO>(url)

    const dataWithProperDate = useMemo(() => {
        if (data !== null) {
            return { ...data, date: new Date(data.date) }
        }
        return null
    }, [data])

    return { data: dataWithProperDate, ...rest }
}

export function useConcertParticipants(concertId: number) {
    const url = `${API_URL}/participants?concert_id=${concertId}`
    return useApiGetArray<Participant>(url)
}


export function useUserConcerts(active: boolean | null = true) {
    const url = `${API_URL}/user-concerts`
    const urlWithFilter = active !== null ? `${url}?active=${active}` : url
    return useApiGetArray<UserConcertItem>(urlWithFilter)
}

export function useAddRemoveUserConcert() {
    const url = `${API_URL}/user-concerts`
    return useApiAddRemoveId<UserConcertItem>(url)
}

export function useListPersons() {
    const url = `${API_URL}/persons`
    return useApiGetArray<Person>(url)
}

export function useCreateConcert() {
    const url = `${API_URL}/concerts`
    return useApiPost<ConcertDTO>(url)
}

export function useUpdateConcert() {
    const url = `${API_URL}/concerts`
    return useApiPatch<ConcertDTO>(url)
}

export function useEditParticipant() {
    const url = `${API_URL}/participants/edit`
    return useApiPost<EditParticipantResponse, EditParticipantResponse>(url)
}


function useTransactionsCommon(url: string) {
    const { data, ...rest } = useApiGetArray<InvoicingEntryDTO>(url)

    const transformedData = useMemo(() => {
        return data.map((item) => {
            return {
                ...item,
                date: new Date(item.date),
            };
        }).sort(
            (a: { date: Date }, b: { date: Date }) => {
                return b.date.getTime() - a.date.getTime();
            }
        ) as InvoicingEntry[];;
    }, [data])

    return { data: transformedData, ...rest }
}

export function useUserTransactions() {
    const url = `${API_URL}/transactions/user`
    return useTransactionsCommon(url)
}

export function usePersonTransactions(personId: number) {
    const url = `${API_URL}/transactions/person/${personId}`
    return useTransactionsCommon(url)
}

export function useUserSettlements() {
    const url = `${API_URL}/settlements/user`
    return useApiGetArray<UserSettlementDTO>(url)
}