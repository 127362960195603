import { Button, Col, List, Row, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { useNavigate } from "react-router-dom";
import { usePersons } from "../PersonsProvider/PersonsProvider";

export type Person = {
  id: number;
  first_name: string;
  last_name: string;
  user: number | null;
  active: boolean;
  external: boolean;
};

export function Persons() {
  const navigate = useNavigate();
  const { loading, persons } = usePersons();

  return (
    <>
      <div style={{ maxWidth: "600px" }}>
        <Row gutter={[8, 8]} style={{ marginBottom: "16px" }}>
          <Col flex="auto">
            <Title
              style={{ paddingLeft: "24px", paddingRight: "24px" }}
              level={4}
            >
              Personer
            </Title>
          </Col>
          <Col>
            <Button
              style={{ marginLeft: "24px", marginRight: "24px" }}
              onClick={() => navigate("/persons/add")}
            >
              Legg til
            </Button>
          </Col>
        </Row>
        <List
          size="large"
          bordered
          dataSource={persons}
          loading={loading}
          renderItem={(person) => {
            return (
              <List.Item>
                <Row align="middle" style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col
                    xs={{ order: 0, span: 16 }}
                    sm={{ order: 0, span: 10 }}
                    md={10}
                  >
                    <Text
                      copyable={{
                        text: `${person.first_name} ${person.last_name}`,
                      }}
                    >
                      {person.first_name} {person.last_name}
                    </Text>
                  </Col>
                  <Col
                    xs={{ order: 2, span: 24 }}
                    sm={{ order: 1, span: 10 }}
                    md={10}
                  >
                    {!person.external && <Tag color="green">Fast musiker</Tag>}
                    {!person.active && (<Tag color="red">Deaktivert</Tag>)}
                    {person.user === null && (<Tag color="volcano">Mangler bruker</Tag>)}
                  </Col>
                  <Col
                    xs={{ order: 1, span: 8 }}
                    sm={{ order: 2, span: 4 }}
                    md={4}
                  >
                    <Button
                      onClick={() => navigate(`/person/${person.id}/edit`)}
                    >
                      Endre
                    </Button>
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
      </div>
    </>
  );
}
