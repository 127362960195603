import { Button, Divider, message, Spin, Row, Col, PageHeader, List } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ExpenseReport, ReportStatus, UserExpenseReport } from "./types";
import { statusToText } from "./ListUserReports";
import Text from "antd/lib/typography/Text";
import Paragraph from "antd/lib/typography/Paragraph";
import { useConcert } from "../api-hooks/api";
import { useDeleteUserReport, useReport } from "../api-hooks/report";

export function ReportDetail() {
    const { reportId } = useParams();
    const reportIdAsNumber = parseInt(reportId || "0")

    // TODO: Add loading indicator and error handling
    const { data: reportData, loading: reportLoading } = useReport(reportIdAsNumber)

    return (reportData !== null ? <ReportDetailView report={reportData} /> : <Spin spinning={reportLoading} />)
}

export function statusToDescription(status: ReportStatus) {
    switch (status) {
        case "draft":
            return "Rapporten er kun et utkast. Velg endre for å sende inn."
        case "approved":
            return "Rapporten er godkjent og kan ikke endres."
        case "pending":
            return "Rapporten er sendt inn og venter på godkjenning."
        case "rejected":
            return "Rapporten ble avvist. Gjør endringer og send inn på nytt."
    }
}


function ReportDetailView({ report }: { report: ExpenseReport }) {

    const navigate = useNavigate()
    const { loading, execute } = useDeleteUserReport(report.id)

    // TODO: Hotfix for issue with _id postfix in django rest framework
    const report2 = report as unknown as UserExpenseReport;

    const { data: concert, loading: concertLoading } = useConcert(report2.activity)

    if (concert === null) {
        return <Spin spinning={concertLoading} />
    }

    const deleteReport = async () => {
        const deleted = await execute()
        if (deleted) {
            navigate("/expenses")
            message.info("Rapport slettet")
        }
    }

    return (<>
        <PageHeader
            onBack={() => navigate("/expenses/")}
            title={`Rapport ${report.id}`}
            tags={<>{statusToText(report.status)}</>}
        />
        <Spin spinning={loading}>
            <Divider orientation="left">Info</Divider>
            <Row gutter={[8, 8]}>
                <Col xs={24}>
                    <Text strong>Konsert:</Text> {`${dayjs(concert.date).format("DD.MM.YYYY")} - ${concert.title}`}
                </Col>
                <Col xs={24}>
                    <Text strong>Kommentar:</Text> {report.comment || "(ingen kommentar lagt inn)"}
                </Col>
            </Row>
            <Divider orientation="left">Utlegg</Divider>
            <List
                size="small"
                footer={<div>{report.items.reduce((acc, current) => acc + current.amount, 0)} kr til sammen</div>}
                bordered
                dataSource={report.items}
                renderItem={item => <List.Item>{item.description} <br /> {item.amount} kr</List.Item>}
            />
            <Divider orientation="left">Handlinger</Divider>
            <Paragraph>
                {statusToDescription(report.status)}
            </Paragraph>

            <Button onClick={() => navigate("/expenses")}>Tilbake til oversikt</Button>
            <Button style={{ marginLeft: "5px" }} onClick={() => deleteReport()} hidden={report.status === "approved"}>Slett rapport</Button>
            <Button style={{ marginLeft: "5px" }} onClick={() => navigate(`/expenses/${report.id}/edit`)} hidden={report.status === "approved"}>Endre rapport</Button>
        </Spin>
    </>)
}
