import { List, PageHeader, Radio, RadioChangeEvent, Spin } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRsvps } from "../hooks/rsvp";
import { Person } from "../Person/Persons";
import { usePersons } from "../PersonsProvider/PersonsProvider";
import { Rsvp } from "../RSVP/rsvp";
import { Concert } from "../types";
import { StatusToLabel } from "../utils";
import dayjs from "dayjs";

type PersonWithRsvp = Person & { rsvp?: Rsvp }

export function RsvpOnBehalfOf({ concert }: { concert: Concert }) {
    const { persons, loading: personsLoading } = usePersons()
    const { rsvps, loading: rsvpsLoading, upsertRsvp } = useRsvps(concert.id)
    const navigate = useNavigate()
    const loading = personsLoading || rsvpsLoading

    const personsWithRsvp: PersonWithRsvp[] = persons.map(p => ({ ...p, rsvp: rsvps.find(r => r.person === p.id) }))

    return <>
        <PageHeader
            onBack={() => navigate("../")}
            title={`${dayjs(concert.date).format("DD.MM.YYYY")} - ${concert.title}`}
            tags={<StatusToLabel status={concert.status} />}
        />
        <List
            bordered
            loading={loading}
            dataSource={personsWithRsvp}
            style={{ maxWidth: "600px" }}
            renderItem={(item) => {
                return (
                    <List.Item key={item.id}>
                        <List.Item.Meta
                            title={`${item.first_name} ${item.last_name}`}
                        />
                        <RsvpOnBehalfOfListItem item={item} concertId={concert.id} upsertRsvp={upsertRsvp} />
                    </List.Item>
                );
            }}
        />
    </>
}


function RsvpOnBehalfOfListItem({ item, concertId, upsertRsvp }: { item: PersonWithRsvp, concertId: number, upsertRsvp: (rsvp: Partial<Rsvp>) => Promise<Rsvp | null> }) {
    const [loading, setLoading] = useState(false)

    const options = [
        { label: 'Ja', value: 'yes' },
        { label: 'Nei', value: 'no' },
    ];

    const onChange = async (e: RadioChangeEvent) => {
        if (item.rsvp?.response === e.target.value) {
            return
        }

        let partialRsvp: Partial<Rsvp>

        // Upsert rsvp
        if (item.rsvp === undefined) {
            partialRsvp = {
                concert: concertId,
                person: item.id,
                response: e.target.value,
            }
        } else {
            partialRsvp = {
                id: item.rsvp.id,
                response: e.target.value,
            }
        }

        setLoading(true)
        await upsertRsvp(partialRsvp)
        setLoading(false)
    }

    return <>
        <Spin spinning={loading}>
            <Radio.Group
                options={options}
                onChange={onChange}
                value={item.rsvp?.response || 'not-replied'}
                optionType="button"
                buttonStyle="solid"
            />
        </Spin>
    </>
}