import React, { useContext } from "react";
import { Person } from "../Person/Persons";
import { useListPersons } from "../api-hooks/api";

type State = {
  persons: Person[];
  loading: boolean;
};

const initialState: State = {
  persons: [],
  loading: false,
};

const PersonContext = React.createContext<State>(initialState);

export function PersonsProvider({ children }: { children: React.ReactNode }) {
  const { data: persons, loading } = useListPersons()

  return (
    <>
      <PersonContext.Provider value={{ persons: persons, loading: loading }}>
        {children}
      </PersonContext.Provider>
    </>
  );
}

export function usePersons() {
  const ctx = useContext(PersonContext);

  if (!ctx) {
    throw new Error(
      "usePersons must be used within a PersonsProvider component"
    );
  }

  const { persons, loading } = ctx;

  return { persons, loading };
}

export function usePersonById(id: number) {
  const { persons } = usePersons();
  return persons.find((item) => item.id === id);
}
