import { Form, Button, Divider, message, Spin, Result } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ExpenseReport } from "./types";
import { Concert } from "../types";
import { FormValues, ReportFormCore } from "./ReportFormCore";
import { useConcerts } from "../api-hooks/api";
import { usePatchUserReport, useReport } from "../api-hooks/report";


export type PatchedReport = Omit<ExpenseReport, "created_at_datetime" | "status" | "person_id">

export function EditReport() {
    const { reportId } = useParams();
    const reportIdAsNumber = parseInt(reportId || "0")

    // TODO: Add loading indicator and error handling
    const { data: concertData, loading: concertsLoading } = useConcerts(true)
    const { data: reportData, loading: reportsLoading } = useReport(reportIdAsNumber)

    return (reportData !== null && concertData.length > 0 ? <EditReportForm concerts={concertData} report={reportData} /> : <Spin spinning={concertsLoading || reportsLoading} />)
}

export function EditReportForm({ concerts, report }: { concerts: Concert[], report: ExpenseReport }) {
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const { loading: patchingReport, execute } = usePatchUserReport()

    // TODO: Bug - the form does not show the correct concert when editing a report
    const initialValues = {
        concertId: report.activity_id,
        items: report.items,
        comment: report.comment,
    }

    const options = concerts.filter(c => c.status === "confirmed" || c.status === "cancelled").map((c) => {
        return { label: `${dayjs(c.date).format("DD.MM.YYYY")} - ${c.title}`, key: c.id }
    })

    const patchReport = async (data: PatchedReport) => {
        const patchedReport = await execute(data)
        if (patchedReport) {
            navigate(`/expenses/${patchedReport.id}`);
            message.success("Endring gjennomført")
        }
    }

    const onFinish = (values: FormValues) => {
        if (values.items === undefined || values.items.length === 0) {
            message.error("Rapporten må inneholde minst ett utlegg")
            return
        }

        const editedReport: PatchedReport = {
            id: report.id,
            items: values.items,
            activity_id: values.concertId,
            comment: values.comment
        }

        patchReport(editedReport);
    };

    if (report.status === "approved") {
        return <Result
            status="500"
            title="Handling ikke tillat"
            subTitle="Rapporten er godkjent og kan ikke endres."
            extra={<Button onClick={() => navigate("/expenses/")} type="primary">Tilbake til rapportoversikten</Button>}
        />
    }

    return (
        <Spin spinning={patchingReport}>
            <Form
                form={form}
                name="report"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={initialValues}
            >
                <Divider orientation="left">Endre rapport</Divider>
                <ReportFormCore options={options} />
                <Form.Item>
                    <Button type="default" onClick={() => navigate(`/expenses`)}>
                        Avbryt
                    </Button>
                    <Button style={{ marginLeft: "5px" }} type="primary" htmlType="submit">
                        Lagre endringer
                    </Button>
                </Form.Item>
            </Form>
        </Spin >)
}